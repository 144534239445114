export function generateUUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}

export function parseISOString(s) {
    if (s) {
        var b = s.split(/\D+/);
        if (b) {
            return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
        }
    }
    return null;
}

export class ElapsedTimer {
    constructor() {
        this.timerStart = new Date();
    }

    start() {
        this.timerStart = new Date();
    }

    end() {
        let timerEnd = new Date();
        return timerEnd - this.timerStart; //in ms;
    }
}
