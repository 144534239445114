export default class StorageManager {
    constructor() {
        this.CONSTANTS = {
            LOCAL_STORAGE: {
                testRunId: "testRunId",
                testRunPIN: "testRunPIN",
                testRunTimestamp: "testRunTimestamp",
                testRunLogCount: "testRunLogCount",
                testRunScriptRunCount: "testRunScriptRunCount",
                testRunScriptNotCompletedCount: "testRunScriptNotCompletedCount",
                testRunSessionValidationFailure: "testRunSessionValidationFailure",
            },
        };
    }

    //local storage

    setTestRunId(value) {
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunId, value);
    }

    getTestRunId() {
        return localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunId);
    }

    deleteTestRunId() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunId);
    }

    setTestRunPIN(value) {
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunPIN, value);
    }

    getTestRunPIN() {
        return localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunPIN);
    }

    deleteTestRunPIN() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunPIN);
    }

    setTestRunTimestamp(value) {
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunTimestamp, value);
    }

    getTestRunTimestamp() {
        return localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunTimestamp);
    }

    deleteTestRunTimestamp() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunTimestamp);
    }

    setTestRunLogEntryCount(value) {
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunLogCount, value);
    }

    // returns 1 if the testRunLogCount was never set
    getTestRunLogEntryCount() {
        let logCountStr = localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunLogCount);
        if (logCountStr) {
            return parseInt(logCountStr);
        } else {
            return 1;
        }
    }

    deleteTestRunLogEntryCount() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunLogCount);
    }

    setTestRunScriptRunCount(url, value) {
        let scriptCountMapStr = localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptRunCount);
        if (scriptCountMapStr) {
            let scriptCountMap = new Map();
            try {
                scriptCountMap = new Map(JSON.parse(scriptCountMapStr));
            } catch (error) {
                console.error(`Storage Manager (set): Script Run Count Map not Well Formed: ${scriptCountMapStr}`);
            }
            scriptCountMap.set(url, value);
            scriptCountMapStr = JSON.stringify(Array.from(scriptCountMap.entries()));
        } else {
            // no map exists yet, create one
            let newMap = new Map();
            newMap.set(url, value);
            scriptCountMapStr = JSON.stringify(Array.from(newMap.entries()));
        }
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptRunCount, scriptCountMapStr);
    }

    // returns 1 if the testRunScriptRunCount was never set
    getTestRunScriptRunCount(url) {
        let scriptCount = 1;
        let scriptCountMapStr = localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptRunCount);
        try {
            if (scriptCountMapStr) {
                let scriptCountMap = new Map(JSON.parse(scriptCountMapStr));
                if (scriptCountMap.has(url)) {
                    scriptCount = parseInt(scriptCountMap.get(url));
                }
            }
        } catch (error) {
            console.error(`Storage Manager (get): Script Run Count Map not Well Formed: ${scriptCountMapStr}`);
        }
        return scriptCount;
    }

    deleteTestRunScriptRunCount() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptRunCount);
    }

    setTestRunScriptNotCompletedCount(url, value) {
        let scriptCountMapStr = localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptNotCompletedCount);
        if (scriptCountMapStr) {
            let scriptCountMap = new Map();
            try {
                scriptCountMap = new Map(JSON.parse(scriptCountMapStr));
            } catch (error) {
                console.error(`Storage Manager (set): Script Not Completed Count Map not Well Formed: ${scriptCountMapStr}`);
            }
            scriptCountMap.set(url, value);
            scriptCountMapStr = JSON.stringify(Array.from(scriptCountMap.entries()));
        } else {
            // no map exists yet, create one
            let newMap = new Map();
            newMap.set(url, value);
            scriptCountMapStr = JSON.stringify(Array.from(newMap.entries()));
        }
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptNotCompletedCount, scriptCountMapStr);
    }

    // returns 0 if the testRunScriptNotCompletedCount was never set
    getTestRunScriptNotCompletedCount(url) {
        let scriptCount = 0;
        let scriptCountMapStr = localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptNotCompletedCount);
        try {
            if (scriptCountMapStr) {
                let scriptCountMap = new Map(JSON.parse(scriptCountMapStr));
                if (scriptCountMap.has(url)) {
                    scriptCount = parseInt(scriptCountMap.get(url));
                }
            }
        } catch (error) {
            console.error(`Storage Manager (get): Script Not Completed Count Map not Well Formed: ${scriptCountMapStr}`);
        }
        return scriptCount;
    }

    deleteTestRunScriptNotCompletedCount() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunScriptNotCompletedCount);
    }

    setTestRunSessionValidationFailure(value) {
        localStorage.setItem(this.CONSTANTS.LOCAL_STORAGE.testRunSessionValidationFailure, value);
    }

    // returns false if the testRunSessionValidationFailure was never set
    getTestRunSessionValidationFailure() {
        let sessionFailure = localStorage.getItem(this.CONSTANTS.LOCAL_STORAGE.testRunSessionValidationFailure);
        if (sessionFailure === undefined) sessionFailure = false;
        return sessionFailure;
    }

    deleteTestRunSessionValidationFailure() {
        localStorage.removeItem(this.CONSTANTS.LOCAL_STORAGE.testRunSessionValidationFailure);
    }

    deleteAll() {
        localStorage.clear();
    }

    // //session storage

    // setInSessionStorage(key, value) {
    //     sessionStorage.setItem(key, value);
    // }

    // getFromSessionStorage(key) {
    //     return sessionStorage.getItem(key);
    // }

    // removeFromSessionStorage(key) {
    //     sessionStorage.removeItem(key);
    // }

    // checkIfExistsSessionStorage(key) {
    //     return getFromSessionStorage(key) !== null;
    // }

    // // cookies

    // setCookie(name, value) {
    //     document.cookie = name + "=" + escape(value) + "; expires=Fri, 31 Dec 2030 23:59:59 GMT; path=/";
    // }

    // getCookie(name) {
    //     var match = document.cookie.match(new RegExp(name + "=([^;]+)"));
    //     if (match) {
    //         return unescape(match[1]);
    //     } else {
    //         return null;
    //     }
    // }

    // removeAllData() {
    //     var cookies = document.cookie.split(";");
    //     for (var i = 0; i < cookies.length; i++) {
    //         var cookie = cookies[i];
    //         var eqPos = cookie.indexOf("=");
    //         var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //         if (cookieNameList.indexOf(name.trim()) > -1) {
    //             document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    //         }
    //     }
    //     window.localStorage.clear();
    // }
}
