/** @format */

// "use strict";
import { ATSCCommands, Constants } from "./A344Test_Constants.js";

class ATSCMediaTimeSim {
    constructor() {
        this.isRunning = false;
        this.startTime = 0;
        this.mtnStartDateTime = "";
        this.mtnTimeElapsed_sec = 0;
        this.mtnTimeExtrapolated_sec = 0;
        this.callbackMTChanged = null;
        this.mtStartDate = new Date();
        this.mtStartDateTime = this.mtStartDate.toISOString();
    }

    subscribeToMediaTimeNotification(callbackFn) {
        this.callbackMTChanged = callbackFn;
        this.isRunning = true;
        let date = new Date();
        this.mtnStartDateTime = date.toISOString();
        requestAnimationFrame(this.mediaTimeLoop);

        if (this.callbackMTChanged) {
            this.callbackMTChanged(this.mtnStartDateTime, this.mtnTimeElapsed_sec, this.mtnTimeExtrapolated_sec);
        }
    }

    unsubscribeToMediaTimeNotification() {
        if (this.isRunning) {
            this.isRunning = false;
            this.callbackMTChanged = null;
        }
    }

    queryMediaTime(callbackFn, timeout) {
        // return one SID after this.timeout ms
        const _self = this;
        this.timer = setTimeout(() => {
            let dateNow = new Date();
            let timeElapsed_sec = (dateNow.getTime() - _self.mtStartDate.getTime()) / 1000;
            callbackFn(_self.mtStartDateTime, timeElapsed_sec);
        }, timeout);
    }

    mediaTimeLoop = (timeStamp) => {
        const TIME_INTERVAL_MS = 400; // update every 400ms
        const TIME_EXTRAPOLATED_MS = 200;
        if (!this.startTime) this.startTime = timeStamp;
        let extrapolatedElapsed = timeStamp - this.startTime;
        this.mtnTimeExtrapolated_sec = extrapolatedElapsed * 0.001;
        if (this.callbackMTChanged) {
            if (extrapolatedElapsed >= TIME_EXTRAPOLATED_MS) {
                this.callbackMTChanged(this.mtnStartDateTime, this.mtnTimeElapsed_sec, this.mtnTimeExtrapolated_sec);
            }
            if (extrapolatedElapsed >= TIME_INTERVAL_MS) {
                this.mtnTimeElapsed_sec += this.mtnTimeExtrapolated_sec;
                this.startTime = timeStamp;
            }
        }
        if (this.isRunning) {
            requestAnimationFrame(this.mediaTimeLoop);
        } else {
            this.startTime = 0;
        }
    };
}

class ATSCServiceIdSim {
    constructor(timeout = 5000) {
        this.timeout = timeout;
        this.timer = null;
        this.currSIDIdx = 0;
        // this.SIDs = ["https://doi.org/10.5239/8A23-2B0B", "https://doi.org/10.5239/8A23-2B0C", "https://doi.org/10.5239/8A23-2B0D", "https://doi.org/10.5239/8A23-2B0E"];
        this.SIDs = ["http://v1000.tv/KVAA/Comp", "http://v1000.tv/KVAA/Comp2"];
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    subscribeToServiceIdNotification(callbackFn) {
        const _self = this;
        // rotate through the SIDs every this.timeout ms
        this.timer = setInterval(() => {
            // let errNo = _self.getRandomInt(100);
            // if (errNo <= 50) {
            //     // 50%
            callbackFn(_self.SIDs[_self.currSIDIdx]);
            if (_self.currSIDIdx < _self.SIDs.length - 1) {
                _self.currSIDIdx++;
            } else {
                _self.currSIDIdx = 0;
            }
            // } else if (errNo <= 75) {
            //     // 25%
            //     callbackFn(undefined);
            // } else {
            //     // 25 %
            //     callbackFn(`Error -${errNo} Simulated Error!`);
            // }
        }, _self.timeout);
    }

    unsubscribeToServiceIdNotification() {
        if (this.timer) {
            if (this.interval) {
                clearInterval(this.timer);
            } else {
                clearTimeout(this.timer);
            }
        }
    }

    queryServiceId(callbackFn, timeout) {
        // return one SID after this.timeout ms
        const _self = this;
        this.timer = setTimeout(() => {
            callbackFn(_self.SIDs[_self.currSIDIdx]);
            if (_self.currSIDIdx < _self.SIDs.length - 1) {
                _self.currSIDIdx++;
            } else {
                _self.currSIDIdx = 0;
            }
        }, timeout);
    }

    getServiceId() {
        let sId = this.SIDs[this.currSIDIdx];
        if (this.currSIDIdx < this.SIDs.length - 1) {
            this.currSIDIdx++;
        } else {
            this.currSIDIdx = 0;
        }
        return sId;
    }
}

export default class ATSCSocketServiceSim {
    constructor(logMgr) {
        this.logManager = logMgr;
        this.mediaTimeSim = new ATSCMediaTimeSim();
        this.callbackMTChanged = null;
        this.serviceIdSim = new ATSCServiceIdSim();
        this.callbackSIDChanged = null;
        this.queryMediaTimeCount = 0;
        this.callbackFn;
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    queryServiceId(callbackFn) {
        this.sendMessage(ATSCCommands.SERVICE_ID, callbackFn);
    }

    queryMediaTime(callbackFn) {
        this.sendMessage(ATSCCommands.RMP_MEDIATIME, callbackFn);
    }

    sendMessage(rpcCall, callbackFn) {
        const _self = this;
        setTimeout(() => {
            _self.sendMessageDelayed(rpcCall, callbackFn);
        }, 50);
    }

    sendMessageDelayed(rpcCall, callbackFn) {
        let messageData;
        let msgTypeStr;

        this.callbackFn = callbackFn;

        switch (rpcCall.method) {
            case ATSCCommands.SERVICE_ID.method:
                let serviceId = this.serviceIdSim.getServiceId();
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {"service": "${serviceId}"},
                    "id": 55
                    }`;
                break;

            case ATSCCommands.RMP_MEDIATIME.method:
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {"currentTime": 60.0,
                    "startDate": "2021-01-01T00:00:00Z"
                    },
                    "id": 61
                    }`;
                break;

            case ATSCCommands.RMP_WALL_CLOCK.method:
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {"wallClock": "2019-01-01T23:59:56.320Z"},
                    "id": 62
                    }`;
                break;

            case ATSCCommands.DEVICE_INFO.method:
                let deviceInfoStr = "";
                if (rpcCall.params != undefined && rpcCall.params.deviceInfoProperties.includes("us.aspect:properties")) {
                    deviceInfoStr = `"deviceInfo": { "us.aspect:properties": { "version": "1.4.4", "HDMI": { "a334Support": 1, "a336VP1Support": 1, "a344OverlaySupport": 1, "a344ScalingSupport": 1 } } },`;
                }
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {
                    "deviceMake": "Acme",
                    "deviceModel": "A300",
                    "aspectVersion": "vwm art: ART v1.3 (B2034)",
                    "deviceInput": {
                    "ArrowUp": 38,
                    "ArrowDown": 40,
                    "ArrowRight": 39,
                    "ArrowLeft": 37,
                    "Select": 13,
                    "Back": 8,
                    "BAAppear": {
                    "label": "NextGen App",
                    "keycode": 500,
                    "img": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD=="
                    }
                    },
                    "deviceId": "01234567-ABCD-0123-abcd-0123456789AB",
                    "deviceSupportedWebSocketAPIs": [
                    "org.atsc.query.ratingLevel",
                    {"method": "org.atsc.query.service", "rev": "20190502"},
                    {"method": "org.atsc.scale-position"}
                    ],
                    ${deviceInfoStr}"deviceSupportedDRMs": [
                    "urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed",
                    "urn:uuid:e2719d58-a985-b3c9-781a-b030af78d30e"
                    ]
                    },
                    "id": 92
                    }`;
                break;

            case ATSCCommands.REQUEST_KEYS.method:
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {"accepted": ["Numeric"]},
                    "id": 43
                    }`;
                break;

            case ATSCCommands.RELINQUISH_KEYS.method:
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {},
                    "id": 44
                    }`;
                break;

            case ATSCCommands.SUBSCRIBE.method:
                msgTypeStr = "[";
                for (let msgIdx = 0; msgIdx < rpcCall.params.msgType.length; msgIdx++) {
                    if (msgIdx) msgTypeStr = msgTypeStr.concat(",");
                    msgTypeStr = msgTypeStr.concat('"' + rpcCall.params.msgType[msgIdx] + '"');
                }
                msgTypeStr = msgTypeStr.concat("]");
                messageData = `{
                    "jsonrpc": "2.0",
                    "result": {
                    "msgType": ${msgTypeStr} 
                    },
                    "id": 51
                    }`;

                if (rpcCall.params.msgType.includes("rmpMediaTimeChange")) {
                    // enable RPM Media Time notifications
                    this.mediaTimeSim.subscribeToMediaTimeNotification((startTime, currTime, etrTime) => {
                        messageData = `{
                        "jsonrpc": "2.0",
                        "method": "org.atsc.notify",
                        "params":{
                        "msgType": "rmpMediaTimeChange",
                        "currentTime": ${currTime},
                        "startDate": ${'"' + startTime + '"'}
                        }
                        }`;
                        if (this.callbackFn) this.callbackFn(rpcCall, JSON.parse(messageData));
                    });
                }
                if (rpcCall.params.msgType.includes("serviceChange")) {
                    // enable service change notifications
                    this.serviceIdSim.subscribeToServiceIdNotification((serviceId) => {
                        messageData = `{
                            "jsonrpc": "2.0",
                            "method": "org.atsc.notify",
                            "params": {
                            "msgType": "serviceChange",
                            "service": ${'"' + serviceId + '"'}
                            }
                            }`;
                        if (this.callbackFn) this.callbackFn(rpcCall, JSON.parse(messageData));
                    });
                }

                break;

            case ATSCCommands.UNSUBSCRIBE.method:
                msgTypeStr = "[";
                for (let msgIdx = 0; msgIdx < rpcCall.params.msgType.length; msgIdx++) {
                    if (msgIdx) msgTypeStr = msgTypeStr.concat(",");
                    msgTypeStr = msgTypeStr.concat('"' + rpcCall.params.msgType[msgIdx] + '"');
                }
                msgTypeStr = msgTypeStr.concat("]");
                messageData = `{
                        "jsonrpc": "2.0",
                        "result": {
                        "msgType": ${msgTypeStr}
                        },
                        "id": 51
                        }`;

                if (rpcCall.params.msgType.includes("rmpMediaTimeChange")) {
                    // disable RPM Media Time notifications
                    this.mediaTimeSim.unsubscribeToMediaTimeNotification();
                }
                if (rpcCall.params.msgType.includes("serviceChange")) {
                    // enable service change notifications
                    this.serviceIdSim.unsubscribeToServiceIdNotification();
                }
                break;

            case ATSCCommands.HELD_SIGNALING_REQ.method:
                if (ATSCCommands.HELD_SIGNALING_REQ.params && ATSCCommands.HELD_SIGNALING_REQ.params.nameList) {
                    // if (ATSCCommands.HELD_SIGNALING_REQ.params.nameList.includes("HELD")) {
                    //     let heldXML = `<HELD xmlns=\\"tag:atsc.org,2016:XMLSchemas/ATSC3/AppSignaling/HELD/1.0\\">`;
                    //     for (let packageIdx = 0; packageIdx < Constants.HELD_ENTRY_PACKAGE_ARRAY.length; packageIdx++) {
                    //         heldXML += Constants.HELD_ENTRY_PACKAGE_ARRAY[packageIdx];
                    //     }
                    //     heldXML += `</HELD>`;
                    //     messageData = `{
                    //     "jsonrpc":"2.0",
                    //     "result":{
                    //        "objectList":[
                    //           {
                    //              "name":"HELD",
                    //              "version":"65",
                    //              "table":${'"' + heldXML + '"'}
                    //           }
                    //        ]
                    //     },
                    //     "id":913
                    //  }`;
                    // } else {
                    //     messageData = `{
                    //         "jsonrpc": "2.0",
                    //         "error": {"code": -9, "message": "Signaling Object Not Supported!"},
                    //         "id": 913
                    //         }`;
                    // }
                    messageData = `{
                        "id":7,
                        "result":{
                           "returnValue":true,
                           "objectList":[
                              {
                                 "table":"<?xml version=\\"1.0\\" encoding=\\"UTF-8\\"?><HELD xmlns=\\"tag:atsc.org.2016:XMLSchemas/ATSC3/AppSignaling/HELD/1.0/\\"><HTMLEntryPackage bbandEntryPageUrl=\\"http://a344test-dev.verance.com//assets/tests/9/2/10\\" appContextId=\\"https://aspect.us\\"/></HELD>",
                                 "name":"HELD"
                              },
                              {
                                 "table":"<?xml version=\\"1.0\\" encoding=\\"UTF-8\\"?><MPD xmlns:xsi=\\"http://www.w3.org/2001/XMLSchema-instance\\" xmlns=\\"urn:mpeg:dash:schema:mpd:2011\\" xsi:schemaLocation=\\"urn:mpeg:dash:schema:mpd:2011 DASH-MPD.xsd\\" >\\"<BaseURL>http://ftp.itec.aau.at/datasets/DASHDataset2014/BigBuckBunny/10sec/bunny_45373bps/BigBuckBunny_10s1.m4s</BaseURL><Period id=\\"1\\"><EventStream schemeIdUri=\\"tag:atsc.org,2016:event\\"><Event presentationTime=\\"300\\" duration=\\"1500\\" id=\\"0\\"><![CDATA[<BroadcastEvent> <Program crid=\\"crid://broadcaster.example.com/ABCDEF\\"/><InstanceDescription><Title xml:lang=\\"en\\">The title</Title><Synopsis xml:lang=\\"en\\" length=\\"medium\\">The description</Synopsis> <ParentalGuidance> <mpeg7:ParentalRating href=\\"urn:dvb:iptv:rating:2014:15\\"/><mpeg7:Region>US</mpeg7:Region></ParentalGuidance></InstanceDescription></BroadcastEvent>]]></Event></EventStream></Period></MPD>",
                                 "name":"MPD"
                              }
                           ]
                        },
                        "jsonrpc":"2.0"
                     }`;
                } else {
                    messageData = `{
                        "jsonrpc": "2.0",
                        "error": {"code": -1, "message": "Signaling Object Request Not Well Formed!"},
                        "id": 913
                        }`;
                }
                break;

            case ATSCCommands.ALERTING.method:
                messageData = `{
                        "jsonrpc":"2.0",
                        "result":{
                           "alertList":[
                              {
                                 "alertingType":"AEAT",
                                 "alertingFragment":"<AEAT xmlns=\\"tag:atsc.org,2016:XMLSchemas/ATSC3/Delivery/AEAT/1.0/\\" xmlns:xsi=\\"http://www.w3.org/2001/XMLSchema-instance\\" xsi:schemaLocation= \\"tag:atsc.org,2016:XMLSchemas/ATSC3/Delivery/AEAT/1.0/ AEAT-1.0-20201106.xsd\\"><AEA aeaId=\\"1\\" issuer=\\"KVER\\" audience=\\"public\\" aeaType=\\"alert\\" refAEAId=\\"1\\" priority=\\"3\\" wakeup=\\"true\\"><Header effective=\\"2018-03-01T17:00:00.000-07:00\\" expires=\\"2018-03-01T18:00:00.000-07:00\\"><EventCode type=\\"SAME\\">EVI</EventCode><EventDesc xml:lang=\\"en\\">AEAT Message 1</EventDesc><Location type=\\"FIPS\\">000000</Location></Header><AEAText xml:lang=\\"en\\">Get under a table</AEAText></AEA><AEA aeaId=\\"2\\" issuer=\\"KVER\\" audience=\\"public\\" aeaType=\\"alert\\" refAEAId=\\"2\\" priority=\\"3\\" wakeup=\\"true\\"><Header effective=\\"2018-03-01T17:00:00.000-07:00\\" expires=\\"2018-03-01T18:00:00.000-07:00\\"><EventCode type=\\"SAME\\">EVI</EventCode><EventDesc xml:lang=\\"en\\">AEAT Message 2</EventDesc><Location type=\\"FIPS\\">000000</Location></Header><AEAText xml:lang=\\"en\\">Put your helmet on</AEAText></AEA><AEA aeaId=\\"3\\" issuer=\\"KVER\\" audience=\\"public\\" aeaType=\\"alert\\" refAEAId=\\"3\\" priority=\\"3\\" wakeup=\\"true\\"><Header effective=\\"2018-03-01T17:00:00.000-07:00\\" expires=\\"2018-03-01T18:00:00.000-07:00\\"><EventCode type=\\"SAME\\">EVI</EventCode><EventDesc xml:lang=\\"en\\">AEAT Message 3</EventDesc><Location type=\\"FIPS\\">000000</Location></Header><AEAText xml:lang=\\"en\\">Stay quiet</AEAText></AEA></AEAT>"
                              }
                           ]
                        },
                        "id":139
                     }`;
                // messageData = `{"id":5,"result":{"alertList":[]},"jsonrpc":"2.0"}`;
                break;

            case ATSCCommands.SET_FILTER.method:
                messageData = `{
                    "jsonrpc":"2.0",
                    "result":{
                    },
                    "id":391
                 }`;
                break;

            // case ATSCCommands.RMP_START.method:
            //     messageData = `{
            //         "jsonrpc": "2.0",
            //         "error": {"code": -21, "message": "Changing RMP playback from the current source is not supported"},
            //         "id": 59
            //         }`;
            //     break;

            case ATSCCommands.DISPLAY_OVERRIDE.method:
                messageData = `{
                    "jsonrpc":"2.0",
                    "result":{
                       "resourceBlocking":false,
                       "displayOverride":true
                    },
                    "id":62
                 }`;
                break;

            case ATSCCommands.COMPONENT_INFO.method:
                messageData = `{
                    "id":4,
                    "jsonrpc":"2.0",
                    "result":{
                        "component":[
                            {
                                "componentID":"1",
                                "descriptor":"componentDesc1",
                                "mediaType":"audio"
                            }
                        ]
                    }
                }`;
                break;

            // case ATSCCommands.COMPONENT_INFO.method:
            //     messageData = `{
            //         "id":4,
            //         "result":{
            //             "component":{
            //                 "componentID":"1",
            //                 "mediaType":"audio",
            //                 "descriptor":"componentDesc1"
            //             }
            //         },
            //         "jsonrpc":"2.0"
            //     }`;
            //     break;

            default:
                messageData = `{
                    "jsonrpc": "2.0",
                    "error": {"code": -9, "message": "A/344 API Not Yet Supported!"},
                    "id": 59
                    }`;
                break;
        }
        if (this.callbackFn) this.callbackFn(rpcCall, JSON.parse(messageData));
    }

    waitForNotification(callbackFn) {
        this.callbackFn = callbackFn;
    }

    stopWaitingForNotifications() {
        this.callbackFn = null;
    }
}
