export const ATSCCommands = {
    RMP_START: {
        method: "org.atsc.setRMPURL",
        params: {
            operation: "startRmp",
            rmpurl: "",
        },
    },
    RMP_MEDIATIME: {
        method: "org.atsc.query.rmpMediaTime",
    },
    RMP_WALL_CLOCK: {
        method: "org.atsc.query.rmpWallClockTime",
    },
    SERVICE_ID: {
        method: "org.atsc.query.service",
    },
    ALERTING: {
        method: "org.atsc.query.alerting",
    },
    SUBSCRIBE: {
        method: "org.atsc.subscribe",
        params: { msgType: "" },
    },
    UNSUBSCRIBE: {
        method: "org.atsc.unsubscribe",
        params: { msgType: "" },
    },
    NOTIFY: {
        method: "org.atsc.notify",
        params: { msgType: "" },
    },
    DEVICE_INFO: {
        method: "org.atsc.query.deviceInfo",
    },
    REQUEST_KEYS: {
        method: "org.atsc.request.keys",
        params: { keys: [] },
    },
    RELINQUISH_KEYS: {
        method: "org.atsc.relinquish.keys",
        params: { keys: [] },
    },
    HELD_SIGNALING_REQ: {
        method: "org.atsc.query.signaling",
        params: {
            nameList: ["HELD"],
        },
    },
    SET_FILTER: {
        method: "org.atsc.setFilterCodes",
    },
    DISPLAY_OVERRIDE: {
        method: "org.atsc.query.displayOverride",
    },
    COMPONENT_INFO: {
        method: "org.atsc.query.recoveredComponentInfo",
    },
};
export var Constants = {
    APP_NAME: "Aspect A/344 Test App",
    VERSION: "1.5",
    LOG_LEVEL: {
        OFF: { level: 0, name: "OFF" },
        ERROR: { level: 1, name: "ERROR" },
        WARNING: { level: 2, name: "WARN" },
        INFO: { level: 3, name: "INFO" },
        DEBUG: { level: 4, name: "DEBUG" },
    },
    ENV_TYPES: {
        DEV: "DEV",
        QA: "QA",
        PROD: "PROD",
    },
    LOG_URLS: {
        PROD: { LOG: "https://a4c-prod-alm.svc.aspect.us/msg", PIN: "https://a4c-prod-alm.svc.aspect.us/utils/pin/a344" },
        QA: { LOG: "https://a4b-qa2-alm.qa2.aspect.us/msg", PIN: "https://a4b-qa2-alm.qa2.aspect.us/utils/pin/a344" },
        DEV: { LOG: "https://a4b-dev2-alm.dev2.aspect.us/msg", PIN: "https://a4b-dev2-alm.dev2.aspect.us/utils/pin/a344" },
    },

    HELD_ENTRY_PACKAGE_ARRAY: [
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_All_APICall.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_All.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_Display.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_ExpectNotify.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_KeyChoice.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_KeyWait.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_Log.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_MultiSection.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_MultiTest.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_TimedWait.json\\"/>',
        '<HTMLEntryPackage appContextId=\\"verance.com\\" bbandEntryPageUrl=\\"http://a344test.verance.com/assets/tests/unit_tests/TestScript_Validation.json\\"/>',
    ],
    WS_DEFAULT_URL: "ws://127.0.0.1:3355",
    ENABLE_WSURL_PARAM: true,
    ENABLE_HELD_TESTSCRIPTS: false,
};
Constants["LOG_LEVEL_DEFAULT"] = Constants.LOG_LEVEL.INFO;
