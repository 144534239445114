/** @format */

//"use strict";

class KeycodeHandler {
    constructor(abortHandler, restartHandler, toggleLogDisplay, escapeSequenceHandler, logMgr) {
        this.callbackAbort = abortHandler;
        this.callbackRestart = restartHandler;
        this.logManager = logMgr;
        this.toggleLogDisplay = toggleLogDisplay;
        this.rightKeycodePressCount = 0;
        this.escapeSequenceHandler = escapeSequenceHandler;
        this.rightKeycodePressTimerId = 0;

        // follow OIPF
        // each keycode on Every Application and Every platform should have only one numeric numer
        // https://www.w3.org/TR/DOM-Level-3-Events-key/
        this.VK_LEFT = { name: "ArrowLeft", code: 37 };
        this.VK_UP = { name: "ArrowUp", code: 38 };
        this.VK_RIGHT = { name: "ArrowRight", code: 39 };
        this.VK_DOWN = { name: "ArrowDown", code: 40 };
        this.VK_ENTER = { name: "Enter", code: 13 };
        this.VK_BLUE = { name: "NA", code: 66 };
        this.VK_RED = { name: "NA", code: 82 };
        this.VK_GREEN = { name: "NA", code: 71 };
        this.VK_YELLOW = { name: "NA", code: 89 };
        this.VK_PLAY = { name: "MediaPlay", code: 415 };
        this.VK_PAUSE = { name: "MediaPause", code: 19 };
        this.VK_STOP = { name: "MediaStop", code: 413 };
        this.VK_FAST_FWD = { name: "MediaFastForward", code: 465 };
        this.VK_REWIND = { name: "MediaRewind", code: 412 };
        this.VK_BACK_SPACE = { name: "Back", code: 8 };
        this.VK_EXIT = { name: "Exit", code: 27 };
        this.VK_CUSTOM_EXIT = { name: "NA", code: 461 };
        this.VK_0 = { name: "Numeric", code: 48 };
        this.VK_1 = { name: "Numeric", code: 49 };
        this.VK_2 = { name: "Numeric", code: 50 };
        this.VK_3 = { name: "Numeric", code: 51 };
        this.VK_4 = { name: "Numeric", code: 52 };
        this.VK_5 = { name: "Numeric", code: 53 };
        this.VK_6 = { name: "Numeric", code: 54 };
        this.VK_7 = { name: "Numeric", code: 55 };
        this.VK_8 = { name: "Numeric", code: 56 };
        this.VK_9 = { name: "Numeric", code: 57 };
        this.VK_SUBTITLE = { name: "Subtitle", code: 476 };
        this.VK_INFO = { name: "Info", code: 457 };
        this.VK_MENU = { name: "ContextMenu", code: 462 };

        this.logManager.debug("KeycodeHandler initialized.");
    }

    // Look for a different configuration of the keys and setput new keycode
    setupKeys(devInfoJsonObj) {
        var searchParams = new URLSearchParams(window.location.search.substr(1));

        const parseData = devInfoJsonObj.result;
        this.logManager.debug("KeycodeHandler:setupKeys() - Device Info from websocket:", devInfoJsonObj);
        if (parseData.deviceInput) {
            // if deviceInput with the key map is returned
            this.logManager.debug("KeycodeHandler:setupKeys() - Parse from API Device Info.");
            // set the new keys keycodes
            this.VK_LEFT.code = parseData.deviceInput.ArrowLeft;
            this.VK_UP.code = parseData.deviceInput.ArrowUp;
            this.VK_RIGHT.code = parseData.deviceInput.ArrowRight;
            this.VK_DOWN.code = parseData.deviceInput.ArrowDown;
            this.VK_BACK_SPACE.code = parseData.deviceInput.Back;
            this.VK_ENTER.code = parseData.deviceInput.Select;
            //this.VK_RED = devInfoJsonObj.deviceInput.Red;
        } else if (parseData.deviceModel) {
            // if deviceModel is returned
            this.logManager.debug("KeycodeHandler:setupKeys() - Custom Device Key for model: " + parseData.deviceModel);
            this.setupKeyForModel(parseData.deviceModel);
        } else if (searchParams.get("deviceID")) {
            this.logManager.debug("KeycodeHandler:setupKeys() - deviceID from query string: " + searchParams.get("deviceID"));
            this.setupKeyForModel(searchParams.get("deviceID"));
        } else {
            // This could be ignored since there are fault back values
        }
        this.logManager.debug(
            "KeycodeHandler:setupKeys() - this.VK_UP:" +
                this.VK_UP.code +
                ", this.VK_DOWN:" +
                this.VK_DOWN.code +
                ", this.VK_LEFT:" +
                this.VK_LEFT.code +
                ", this.VK_RIGHT:" +
                this.VK_RIGHT.code +
                ", this.VK_ENTER:" +
                this.VK_ENTER.code +
                ", this.VK_BACK_SPACE:" +
                this.VK_BACK_SPACE.code
        );
    }

    // function that given the model does the setup of any keys
    setupKeyForModel(model) {
        this.logManager.debug("KeycodeHandler:setupKeyForModel()");
        // we don't have any other devices
        // when and if we have them it would make sense to map everything to a .json file
        if (model == "deviceSony" || model == "device2Sony" || model == '"SonyTVNABPilotDemo"') {
            this.VK_LEFT.code = 114;
            this.VK_UP.code = 112;
            this.VK_RIGHT.code = 115;
            this.VK_DOWN.code = 113;
            this.VK_BACK_SPACE.code = 116;
            this.VK_CUSTOM_EXIT.code = 117;
        }
    }

    checkAndHandleEscapeSequence(defaultKeyHandler) {
        // Handle possible escape sequence ("3x ArrowRight" within 250ms of each other):
        // 1. cancel the reset timer
        if (this.rightKeycodePressTimerId) {
            clearTimeout(this.rightKeycodePressTimerId);
            this.rightKeycodePressTimerId = 0;
        }
        // 2. keep track of current state of escape sequence
        this.rightKeycodePressCount++;
        this.logManager.debug(`KeycodeHandler:checkAndHandleEscapeSequence(): escape sequence count is ${this.rightKeycodePressCount}`);
        if (this.rightKeycodePressCount === 3) {
            // 3a. invoke the handler for the escape sequence!
            this.rightKeycodePressCount = 0;
            this.logManager.debug(`KeycodeHandler:checkAndHandleEscapeSequence(): escape sequence activated!`);
            if (this.escapeSequenceHandler) this.escapeSequenceHandler();
        } else {
            // 3b. setup a timer (250ms) to
            //     - reset the this.rightKeycodePressCount and
            //     - invoke the handler for ArrowRight
            const _self = this;
            this.rightKeycodePressTimerId = setTimeout(() => {
                this.rightKeycodePressCount = 0;
                this.logManager.debug(`KeycodeHandler:checkAndHandleEscapeSequence(): escape sequence timeout!`);
                if (defaultKeyHandler) defaultKeyHandler();
            }, 500);
        }
    }

    menuKeydownHandler(keyCode, callbackUp, callbackDown, callbackSelect) {
        this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${keyCode}`);
        switch (keyCode) {
            case this.VK_UP.code:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${this.VK_UP.name}`);
                if (callbackUp) callbackUp();
                break;
            case this.VK_LEFT.code:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${this.VK_LEFT.name}`);
                if (this.callbackRestart) this.callbackRestart();
                break;
            case this.VK_RIGHT.code:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${this.VK_RIGHT.name}`);
                // Handle possible escape sequence
                this.checkAndHandleEscapeSequence(this.toggleLogDisplay);
                break;
            case this.VK_DOWN.code:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${this.VK_DOWN.name}`);
                if (callbackDown) callbackDown();
                break;
            case this.VK_BACK_SPACE.code:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${this.VK_BACK_SPACE.name}`);
                if (callbackSelect) callbackSelect(true);
                break;
            case this.VK_ENTER.code:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: ${this.VK_ENTER.name}`);
                // notify of selection
                if (callbackSelect) callbackSelect(false);
                break;
            default:
                this.logManager.debug(`KeycodeHandler:menuKeydownHandler() - keydown: not handled`);
                break;
        }
    }

    enableMenuKeys(callbackUp, callbackDown, callbackSelect) {
        this.logManager.debug(`KeycodeHandler:enableMenuKeys()`);

        const _self = this;
        document.body.onkeydown = function (e) {
            _self.menuKeydownHandler(e.keyCode, callbackUp, callbackDown, callbackSelect);
            e.preventDefault();
        };
    }

    defaultKeydownHandler(keyCode) {
        this.logManager.debug(`KeycodeHandler:defaultKeydownHandler() - keydown: ${keyCode}`);
        switch (keyCode) {
            case this.VK_BACK_SPACE.code:
                this.logManager.debug(`KeycodeHandler:defaultKeydownHandler() - keydown: ${this.VK_BACK_SPACE.name}`);
                this.callbackAbort();
                break;
            case this.VK_RIGHT.code:
                this.logManager.debug(`KeycodeHandler:defaultKeydownHandler() - keydown: ${this.VK_RIGHT.name}`);
                // Handle possible escape sequence
                this.checkAndHandleEscapeSequence(this.toggleLogDisplay);
                break;
            // case this.VK_LEFT.code:
            //     this.logManager.debug(`KeycodeHandler:defaultKeydownHandler() - keydown: ${this.VK_LEFT.name}`);
            //     if (this.callbackRestart) this.callbackRestart();
            //     break;
            default:
                this.logManager.debug(`KeycodeHandler:defaultKeydownHandler() - keydown: not handled`);
                break;
        }
    }

    resetKeydownKeys() {
        this.logManager.debug("KeycodeHandler:resetKeydownKeys()");

        const _self = this;
        document.body.onkeydown = function (e) {
            _self.defaultKeydownHandler(e.keyCode);
            e.preventDefault();
        };
    }

    customKeydownHandler(keyCode, keyList, callbackFn) {
        let keyName;
        this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${keyCode}`);
        switch (keyCode) {
            case this.VK_UP.code:
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${this.VK_UP.name}`);
                keyName = keyList.find((key) => key === this.VK_UP.name);
                if (keyName) {
                    if (callbackFn) callbackFn(keyName);
                } else {
                    this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                    // default handling...
                    this.defaultKeydownHandler(keyCode);
                }
                break;

            case this.VK_DOWN.code:
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${this.VK_DOWN.name}`);
                keyName = keyList.find((key) => key === this.VK_DOWN.name);
                if (keyName) {
                    if (callbackFn) callbackFn(keyName);
                } else {
                    this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                    // default handling...
                    this.defaultKeydownHandler(keyCode);
                }
                break;

            case this.VK_LEFT.code:
                this.logManager.debug(`customKeydownHandler() - keydown: ${this.VK_LEFT.name}`);
                keyName = keyList.find((key) => key === this.VK_LEFT.name);
                if (keyName) {
                    if (callbackFn) callbackFn(keyName);
                } else {
                    this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                    // default handling...
                    this.defaultKeydownHandler(keyCode);
                }
                break;

            case this.VK_RIGHT.code:
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${this.VK_RIGHT.name}`);
                const _self = this;
                // Handle possible escape sequence
                this.checkAndHandleEscapeSequence(() => {
                    keyName = keyList.find((key) => key === _self.VK_RIGHT.name);
                    if (keyName) {
                        if (callbackFn) callbackFn(keyName);
                    } else {
                        this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                        // default handling...
                        this.defaultKeydownHandler(keyCode);
                    }
                });
                break;

            case this.VK_0.code:
            case this.VK_1.code:
            case this.VK_2.code:
            case this.VK_3.code:
            case this.VK_4.code:
            case this.VK_5.code:
            case this.VK_6.code:
            case this.VK_7.code:
            case this.VK_8.code:
            case this.VK_9.code:
                // all numbers keycode name is "Numeric" so we just use VK_0 here
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${this.VK_0.name} (${keyCode - this.VK_0.code})`);
                keyName = keyList.find((key) => key === this.VK_0.name);
                if (keyName) {
                    if (callbackFn) callbackFn(keyName);
                } else {
                    this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                    // default handling...
                    this.defaultKeydownHandler(keyCode);
                }
                break;

            case this.VK_ENTER.code:
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${this.VK_ENTER.name}`);
                keyName = keyList.find((key) => key === this.VK_ENTER.name);
                if (keyName) {
                    if (callbackFn) callbackFn(keyName);
                } else {
                    this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                    // default handling...
                    this.defaultKeydownHandler(keyCode);
                }
                break;

            case this.VK_BACK_SPACE.code:
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: ${this.VK_BACK_SPACE.name}`);
                keyName = keyList.find((key) => key === this.VK_BACK_SPACE.name);
                if (keyName) {
                    if (callbackFn) callbackFn(keyName);
                } else {
                    this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                    // default handling...
                    this.defaultKeydownHandler(keyCode);
                }
                break;

            default:
                this.logManager.debug(`KeycodeHandler:customKeydownHandler() - keydown: default handling`);
                // default handling...
                this.defaultKeydownHandler(keyCode);
                break;
        }
    }
    enableKeydownKeys(keyList, callbackFn) {
        this.logManager.debug(`KeycodeHandler:enableKeydownKeys() - ${keyList}`);
        const _self = this;
        // $("body").off("keydown");
        // $("body").keydown(function (e) {
        document.body.onkeydown = function (e) {
            _self.customKeydownHandler(e.keyCode, keyList, callbackFn);
            e.preventDefault();
        };
        // });
    }
}

class DisplayHandler {
    constructor(canvasElement, logMgr) {
        this.canvas = canvasElement;
        this.logManager = logMgr;
        // This prevents the text being drawn on the canvas to stretch if the canvas is stretched to fit by CSS
        this.canvas.height = this.canvas.width * (this.canvas.clientHeight / this.canvas.clientWidth);
        this.ctx = this.canvas.getContext("2d");
        this.choiceList = [];
        this.choicePrompt = "";
        this.currentMessage;
        this.validated;
        // const FONT_HEADER = "normal normal 700 48px georgia,elephant";
        const FONT_HEADER = "700 48px georgia,elephant";
        const FONT_VERSION = "700 18px georgia,elephant";
        const FONT_INFO = "300 34px arial,helvetica";
        const FONT_LABEL = "300 18px arial,helvetica";
        const FONT_VALUE = "100 18px courier,monospace,consolas";
        this.Fonts = {
            HEADER: {
                font: FONT_HEADER,
                fontMetrics: this.measureText("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", FONT_HEADER),
                paddingBottom: 35,
            },
            VERSION: {
                font: FONT_VERSION,
                fontMetrics: this.measureText("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", FONT_VERSION),
                paddingBottom: 5,
            },
            INFO: {
                font: FONT_INFO,
                fontMetrics: this.measureText("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", FONT_INFO),
                paddingBottom: 15,
            },
            LABEL: {
                font: FONT_LABEL,
                fontMetrics: this.measureText("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", FONT_LABEL),
                paddingBottom: 15,
            },
            VALUE: {
                font: FONT_VALUE,
                fontMetrics: this.measureText("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", FONT_VALUE),
                paddingBottom: 10,
            },
        };
        const TOP = 20;
        const LEFT = 10;
        this.headerPosX = LEFT;
        this.headerPosY = TOP + this.Fonts.HEADER.fontMetrics.height;
        this.headerYPadding = this.Fonts.HEADER.paddingBottom;
        this.infoPosX = LEFT;
        this.infoPosY = this.headerPosY + this.headerYPadding + this.Fonts.LABEL.fontMetrics.height;
        this.infoYPadding = this.Fonts.LABEL.paddingBottom;
        this.testPosX = LEFT;
        this.testPosY = this.infoPosY + this.infoYPadding + this.Fonts.LABEL.fontMetrics.height;
        this.testYPadding = this.Fonts.LABEL.paddingBottom;
        this.promptPosX = LEFT;
        this.promptPosY = this.testPosY + this.testYPadding + this.Fonts.VALUE.fontMetrics.height;
        this.promptYPadding = this.Fonts.VALUE.paddingBottom;
        this.validationImageSize = 25;
        this.validationImagePosX = LEFT;
        this.validationImagePosY = this.promptPosY + this.promptYPadding * 2;
        this.messageYPadding = this.Fonts.VALUE.paddingBottom;
        this.messagePosX = LEFT;
        this.messagePosY = this.promptPosY + this.promptYPadding + this.Fonts.VALUE.fontMetrics.height;
        this.messageYPadding = this.Fonts.VALUE.paddingBottom;
        this.debugDisplay = false;

        this.imagePass = new Image();
        this.imagePass.src = `/assets/images/pass.png`;
        this.imageFail = new Image();
        this.imageFail.src = `/assets/images/fail.png`;
    }

    getFontSize(fontValue) {
        let pxIdx = fontValue.indexOf("px");
        if (pxIdx >= 0) {
            let fontSizeIdx = fontValue.lastIndexOf(" ", pxIdx) + 1;
            let fontSizeLength = pxIdx - fontSizeIdx;
            let fontSize = fontValue.substr(fontSizeIdx, fontSizeLength);
            return Number(fontSize);
        }
    }
    measureText(text, font) {
        let tmpFont = this.ctx.font;
        this.ctx.font = font;
        let metrics = this.ctx.measureText(text);
        this.ctx.font = tmpFont;

        let textMetrics;
        let fontSize = this.getFontSize(font);
        let asc = Math.ceil(fontSize * 0.9);
        let des = Math.ceil(fontSize * 0.2);
        textMetrics = {
            fontBoundingBoxAscent: asc,
            fontBoundingBoxDescent: des,
            width: metrics.width,
            height: asc + des,
        };
        return textMetrics;
    }

    getErrorBackgroundStyle() {
        // // Create gradient
        // let backgroundGradientStyle = this.ctx.createLinearGradient(0, 0, this.canvas.width, 0);
        // let backgroundCanvasColor = $("#canvas").css("background-color");
        // backgroundGradientStyle.addColorStop(0, backgroundCanvasColor); //"#008eaa");
        // backgroundGradientStyle.addColorStop(0.5, backgroundCanvasColor); //"#008eaa");
        // backgroundGradientStyle.addColorStop(1, "red");
        // return backgroundGradientStyle;
        return "#f00";
    }

    displayHeader(headerInfo, versionInfo) {
        const linePadding = this.Fonts.HEADER.paddingBottom / 2;
        this.displayData(this.headerPosX, this.headerPosY, versionInfo, this.Fonts.VERSION.font, 0, headerInfo, this.Fonts.HEADER.font, linePadding);
    }

    displayInfo(message, backgroundStyle) {
        this.displayData(this.infoPosX, this.infoPosY, "", this.Fonts.VALUE.font, 0, message, this.Fonts.INFO.font, undefined, backgroundStyle);
    }

    displayTestInfo(message) {
        const linePadding = this.Fonts.LABEL.paddingBottom / 2;
        this.displayData(this.testPosX, this.testPosY, "", this.Fonts.VALUE.font, 0, message, this.Fonts.LABEL.font, linePadding);
    }

    displayPrompt(message) {
        const linePadding = this.Fonts.VALUE.paddingBottom / 2;
        this.displayData(this.promptPosX, this.promptPosY, message, this.Fonts.VALUE.font, 0, "", this.Fonts.LABEL.font, linePadding, "#000");
    }

    displaySize(fontSize) {
        let pxIdx = this.Fonts.VALUE.font.indexOf("px");
        if (pxIdx >= 0) {
            let fontSizeIdx = this.Fonts.VALUE.font.lastIndexOf(" ", pxIdx) + 1;
            let newFont = this.Fonts.VALUE.font.substr(0, fontSizeIdx) + String(fontSize) + this.Fonts.VALUE.font.substr(pxIdx);

            this.displayMessageUsingFont(this.currentMessage, newFont, this.validated);
        }
    }

    displayMessage(message, validated, sessionHasFailure) {
        this.displayMessageUsingFont(message, this.Fonts.VALUE.font, validated, sessionHasFailure);
    }

    displayMessageUsingFont(message, messageFont, validated, sessionHasFailure) {
        this.currentMessage = message;
        this.validated = validated;

        const _self = this;
        let IMG_SIZE = 0;
        if (validated != undefined) {
            IMG_SIZE = this.validationImageSize;
            if (validated === true) {
                let imageLoaded = _self.imagePass.complete && _self.imagePass.naturalHeight !== 0;
                if (imageLoaded) _self.ctx.drawImage(_self.imagePass, _self.validationImagePosX, _self.validationImagePosY, IMG_SIZE, IMG_SIZE);
            } else {
                let imageLoaded = _self.imageFail.complete && _self.imageFail.naturalHeight !== 0;
                if (imageLoaded) _self.ctx.drawImage(_self.imageFail, _self.validationImagePosX, _self.validationImagePosY, IMG_SIZE, IMG_SIZE);
            }
        }

        let metrics = this.measureText(message, messageFont);
        let fontHeight = metrics.height;
        const linePadding = metrics.fontBoundingBoxDescent * 2;

        this.messagePosY = this.promptPosY + this.promptYPadding + fontHeight;

        // split multi-line messages
        const wrap = (s, w) => s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, "g"), "$1\n");
        // calculate how many characters will fit the canvas (given the font)..
        let testMsg = "W";
        while (true) {
            let lineMetrics = this.measureText(testMsg, messageFont);
            if (lineMetrics.width <= this.canvas.width - this.messagePosX) {
                testMsg = testMsg.concat("W");
            } else {
                break;
            }
        }
        let wrappedLines = wrap(message, testMsg.length - 1);
        let messageLines = wrappedLines.split("\n");
        for (let lineIdx = 0; lineIdx < messageLines.length; lineIdx++) {
            this.displayData(
                this.messagePosX,
                this.messagePosY + IMG_SIZE + linePadding + lineIdx * fontHeight,
                `  ${messageLines[lineIdx]}`,
                messageFont,
                0,
                "",
                this.Fonts.LABEL.font,
                lineIdx === messageLines.length - 1 ? linePadding : 0,
                sessionHasFailure ? "#000" : "#fff",
                sessionHasFailure ? this.getErrorBackgroundStyle() : undefined
            );
        }
    }

    displayChoiceList(choiceList, choicePrompt, selectedIdx) {
        this.choiceList = choiceList;
        this.selectedIdx = selectedIdx;
        this.choicePrompt = choicePrompt;
        // reset the Y position for the message in case a previous message with a bigger/smaller font was used
        this.messagePosY = this.promptPosY + this.promptYPadding + this.Fonts.VALUE.fontMetrics.height;

        this.displayPrompt(choicePrompt);
        for (let choiceIdx = 0; choiceIdx < this.choiceList.length; choiceIdx++) {
            let fillStyle; // default
            if (choiceIdx === selectedIdx) {
                fillStyle = "#000";
            }
            this.displayData(
                this.messagePosX,
                this.messagePosY + this.Fonts.LABEL.paddingBottom + choiceIdx * (this.Fonts.LABEL.fontMetrics.height + this.Fonts.LABEL.paddingBottom),
                this.choiceList[choiceIdx],
                this.Fonts.VALUE.font,
                0,
                "",
                this.Fonts.LABEL.font,
                0,
                fillStyle
            );
        }
    }

    displayData(left, top, dataStr, dataFont, dataLeftPaddingPix, label, labelFont, linePadding, fillStyle = "#fff", backgroundStyle) {
        let labelWidth = 0;
        let labelHeightPix = 0;
        let dataWidth = 0;
        let dataMetrics = this.measureText(dataStr, dataFont);
        let dataHeightPix = dataMetrics.height;

        if (label) {
            labelWidth = this.measureText(label, labelFont).width;
            labelHeightPix = this.measureText(label, labelFont).height;
        }
        dataWidth = dataMetrics.width;

        const clearHeightPix = Math.max(dataHeightPix, labelHeightPix);

        // now display the info on the canvas
        this.ctx.clearRect(left, top + dataMetrics.fontBoundingBoxDescent - clearHeightPix, this.canvas.width, clearHeightPix + 5);
        if (backgroundStyle) {
            this.ctx.fillStyle = backgroundStyle;
            this.ctx.fillRect(left, top + dataMetrics.fontBoundingBoxDescent - clearHeightPix, this.canvas.width - left * 2, clearHeightPix + 5);
        }
        this.ctx.fillStyle = fillStyle;
        if (label) {
            // display the label message
            if (label.includes("Error")) this.ctx.fillStyle = "#000";
            this.ctx.font = labelFont;
            this.ctx.fillText(label, left, top);

            if (this.debugDisplay) {
                let labelMetrics = this.measureText(label, labelFont);
                // show the line for the text baseline (i.e. top)
                this.drawLine(left, top, left + labelWidth, top, "#f00");
                // show the line for the text bottom (i.e. top+descent)
                this.drawLine(left, top + labelMetrics.fontBoundingBoxDescent, left + labelWidth, top + labelMetrics.fontBoundingBoxDescent, "#0f0");
                // show the line for the text top (i.e. top+descent-height)
                this.drawLine(left, top - labelMetrics.fontBoundingBoxAscent, left + labelWidth, top - labelMetrics.fontBoundingBoxAscent, "#00f");
            }
        }
        // display the data
        this.ctx.fillStyle = fillStyle;
        if (!dataStr || dataStr.includes("Error")) this.ctx.fillStyle = "#000";
        this.ctx.font = dataFont;
        this.ctx.fillText(dataStr, left + labelWidth + dataLeftPaddingPix, top);

        if (this.debugDisplay) {
            // show the line for the text baseline (i.e. top)
            this.drawLine(left + labelWidth + dataLeftPaddingPix, top, left + labelWidth + dataLeftPaddingPix + dataWidth, top, "#f00");
            // show the line for the text bottom (i.e. top+descent)
            this.drawLine(
                left + labelWidth + dataLeftPaddingPix,
                top + dataMetrics.fontBoundingBoxDescent,
                left + labelWidth + dataLeftPaddingPix + dataWidth,
                top + dataMetrics.fontBoundingBoxDescent,
                "#0f0"
            );
            this.drawLine(
                left + labelWidth + dataLeftPaddingPix,
                top - dataMetrics.fontBoundingBoxAscent,
                left + labelWidth + dataLeftPaddingPix + dataWidth,
                top - dataMetrics.fontBoundingBoxAscent,
                "#00f"
            );
        }

        if (linePadding) {
            this.drawLine(left, top + dataMetrics.fontBoundingBoxDescent + linePadding, this.canvas.width - left, top + dataMetrics.fontBoundingBoxDescent + linePadding, "#fff");
        }
    }

    drawLine(x1, y1, x2, y2, color) {
        // show the line for the text baseline (i.e. top)
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = 1;
        this.ctx.beginPath();
        this.ctx.moveTo(x1, y1);
        this.ctx.lineTo(x2, y2);
        this.ctx.closePath();
        this.ctx.stroke();
    }

    clearDisplay(keepHeader, keepTestInfo, keepPrompt) {
        if (!keepHeader) {
            this.displayHeader(" ", " ");
            this.displayInfo(" ");
        }
        if (!keepTestInfo) this.displayTestInfo(" ");
        if (!keepPrompt) this.displayPrompt(" ");
        this.ctx.clearRect(0, this.messagePosY - this.messageYPadding * 2, this.canvas.width, this.canvas.height);
    }

    displaySelectionUp() {
        if (this.selectedIdx > 0) {
            this.clearDisplay(true, true, true);
            this.displayChoiceList(this.choiceList, this.choicePrompt, this.selectedIdx - 1);
        }
    }

    displaySelectionDown() {
        if (this.selectedIdx < this.choiceList.length - 1) {
            this.clearDisplay(true, true, true);
            this.displayChoiceList(this.choiceList, this.choicePrompt, this.selectedIdx + 1);
        }
    }
}

export default class UIManager {
    constructor(canvasElement, abortHandler, restartHandler, escapeSequenceHandler, logMgr) {
        const _self = this;
        this.displayHandler = new DisplayHandler(canvasElement, logMgr);
        this.keycodeHandler = new KeycodeHandler(
            abortHandler,
            restartHandler,
            function () {
                _self.toggleLogDisplay();
            },
            escapeSequenceHandler,
            logMgr
        );
        this.logManager = logMgr;
        this.logManager.debug("UIManager initialized.");
        this.enableLogDisplay = false;
        this.appVisibleState = true;
        this.appFocusState = true;
        this.docHiddenPropName = undefined;

        // Set the name of the hidden property and the change event for visibility
        var visibilityChange;
        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            this.docHiddenPropName = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            this.docHiddenPropName = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            this.docHiddenPropName = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        // Warn if the browser doesn't support addEventListener or the Page Visibility API
        if (typeof document.addEventListener === "undefined" || this.docHiddenPropName === undefined) {
            this.logManager.error("The platform browser does NOT support the Page Visibility API.");
        } else {
            // Handle page visibility change
            document.addEventListener(
                visibilityChange,
                () => {
                    if (document[_self.docHiddenPropName]) {
                        _self.appVisibleState = false;
                    } else {
                        _self.appVisibleState = true;
                    }
                },
                true
            );
        }

        // The following will keep track of the focus status of the app
        document.addEventListener(
            blur,
            () => {
                _self.appFocusState = false;
            },
            true
        );
        document.addEventListener(
            focus,
            () => {
                _self.appFocusState = true;
            },
            true
        );
    }

    // Look for a different configuration of the keys and setput new keycode
    setupKeys(devInfoJsonObj) {
        try {
            this.logManager.debug("UIManager:setupKeys()");
            this.keycodeHandler.setupKeys(devInfoJsonObj);
        } catch (error) {
            this.logManager.error(`Error during key setup: ${error}`);
        }
    }

    waitForSelection(choiceList, choiceSelectionCallback, promptMsg) {
        try {
            this.logManager.debug("UIManager:waitForSelection()");
            this.displayHandler.displayChoiceList(choiceList, promptMsg, 0);
            const _self = this;
            this.keycodeHandler.enableMenuKeys(
                function () {
                    // user selected up
                    _self.displayHandler.displaySelectionUp();
                },
                function () {
                    // user selected down
                    _self.displayHandler.displaySelectionDown();
                },
                function (abort) {
                    // user made a selection
                    // first reset the default keypress handling
                    _self.keycodeHandler.resetKeydownKeys();
                    // then invoke the selection callback
                    choiceSelectionCallback(_self.displayHandler.selectedIdx, abort);
                }
            );
        } catch (error) {
            this.logManager.error(`Error while waiting for selection: ${error}`);
        }
    }

    clearDisplay(keepHeader, keepTestInfo, resetKeys, keepPrompt) {
        try {
            this.logManager.debug("UIManager:clearDisplay()");

            // clear the choice list from the display
            this.displayHandler.clearDisplay(keepHeader, keepTestInfo, keepPrompt);
            // make sure user keys do not cause callbacks to be invoked (besides default handlers: abort, restart, log-toggle)
            if (resetKeys) this.keycodeHandler.resetKeydownKeys();
        } catch (error) {
            this.logManager.error(`Error while clearing the display: ${error}`);
        }
    }

    displayList(itemList, promptMsg) {
        try {
            this.logManager.debug("UIManager:displayList()");

            this.displayHandler.displayChoiceList(itemList, promptMsg);
        } catch (error) {
            this.logManager.error(`Error while displaying list: ${error}`);
        }
    }

    waitKeypress(waitKeys, keyPressCallback, message) {
        try {
            this.logManager.debug(`UIManager:waitKeypress() - ${waitKeys}`);
            const _self = this;

            if (message) {
                this.displayHandler.displayPrompt(message);
            }
            this.keycodeHandler.enableKeydownKeys(waitKeys, function (keyName) {
                if (message) {
                    // clear the prompt
                    _self.displayHandler.displayPrompt("");
                }
                // first reset the default keypress handling
                _self.keycodeHandler.resetKeydownKeys();
                // then invoke the selection callback
                keyPressCallback(keyName);
            });
        } catch (error) {
            this.logManager.error(`Error while waiting for key press: ${error}`);
        }
    }

    waitForKeySelection(keyPromptList, selectionCallback, message) {
        try {
            this.logManager.debug("UIManager:waitForKeySelection()");
            const _self = this;

            let keyList = [];
            let promptList = [];
            for (let keyIdx = 0; keyIdx < keyPromptList.length; keyIdx++) {
                keyList.push(keyPromptList[keyIdx].Keycode);
                promptList.push(keyPromptList[keyIdx].Prompt);
            }
            this.displayHandler.displayChoiceList(promptList, message);
            this.keycodeHandler.enableKeydownKeys(keyList, function (keyName) {
                if (message) {
                    // clear the prompt
                    _self.displayHandler.displayPrompt("");
                }
                // first reset the default keypress handling
                _self.keycodeHandler.resetKeydownKeys();
                // then invoke the selection callback
                selectionCallback(keyName);
            });
        } catch (error) {
            this.logManager.error(`Error while waiting for key selection: ${error}`);
        }
    }

    displayHeader(headerInfo, versionInfo) {
        try {
            this.logManager.debug("UIManager:displayHeader()");

            this.displayHandler.displayHeader(headerInfo, versionInfo);
        } catch (error) {
            this.logManager.error(`Error while displaying header: ${error}`);
        }
    }

    displayInfo(serviceId, tip, backgroundStyle) {
        try {
            this.logManager.debug("UIManager:displayInfo()");

            this.displayHandler.displayInfo(serviceId, tip, backgroundStyle);
        } catch (error) {
            this.logManager.error(`Error while displaying info: ${error}`);
        }
    }

    displayTestInfo(testInfo) {
        try {
            this.logManager.debug("UIManager:displayTestInfo()");

            this.displayHandler.displayTestInfo(testInfo);
        } catch (error) {
            this.logManager.error(`Error while displaying test info: ${error}`);
        }
    }

    displayPrompt(message) {
        if (message) {
            this.displayHandler.displayPrompt(message);
        }
    }

    displayMessage(message, validated, sessionHasFailure) {
        try {
            this.logManager.debug("UIManager:displayMessage()");

            this.displayHandler.clearDisplay(true, true, true);

            this.displayHandler.displayMessage(message, validated, sessionHasFailure);
        } catch (error) {
            this.logManager.error(`Error while displaying message: ${error}`);
        }
    }

    displaySize(fontSize) {
        try {
            this.displayHandler.clearDisplay(true, true, true);

            this.displayHandler.displaySize(fontSize);
        } catch (error) {
            this.logManager.error(`Error while setting display size: ${error}`);
        }
    }

    toggleLogDisplay() {
        try {
            this.logManager.debug("UIManager:toggleLogDisplay()");

            this.enableLogDisplay = !this.enableLogDisplay;
            if (this.enableLogDisplay) {
                this.showLogDisplay();
            } else {
                this.hideLogDisplay();
            }
        } catch (error) {
            this.logManager.error(`Error while toggling display: ${error}`);
        }
    }

    showLogDisplay() {
        this.logManager.debug("UIManager:showLogDisplay()");
        let element = document.getElementById("log-container");
        element.style.display = "block";
    }

    hideLogDisplay() {
        this.logManager.debug("UIManager:hideLogDisplay()");
        let element = document.getElementById("log-container");
        element.style.display = "none";
    }

    hide() {
        try {
            let element = document.getElementById("canvas-container");
            let style = window.getComputedStyle(element);
            if (style.display != "none") {
                element.classList.add("slide-out");
                setTimeout(function () {
                    // element.hide();
                    element.style.display = "none";
                    element.classList.remove("slide-out");
                }, 3500);
            }
        } catch (error) {
            this.logManager.error(`Error while hiding app: ${error}`);
        }
    }

    show() {
        try {
            let element = document.getElementById("canvas-container");
            let style = window.getComputedStyle(element);
            if (style.display === "none") {
                element.style.display = "block";
                element.classList.add("slide-in");
            }
        } catch (error) {
            this.logManager.error(`Error while showing app: ${error}`);
        }
    }

    isVisible() {
        this.logManager.info(`isVisible()`, {
            this_appVisibleState: this.appVisibleState,
            document_visibilityState: document.visibilityState,
            document_hidden: document[this.docHiddenPropName],
        });
        return this.appVisibleState && document.visibilityState === "visible" && !document[this.docHiddenPropName];
    }

    hasFocus() {
        this.logManager.info(`hasFocus()`, { appFocusState: this.appFocusState });
        return this.appFocusState;
    }
}
