// "use strict";
import { Constants } from "./A344Test_Constants.js";

String.prototype.escapeSpecialChars = function () {
    return this.replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f")
        .replace(/\\{/g, "\\{")
        .replace(/\\}/g, "\\}");
};

export default class LogManager {
    constructor(appName, appVersion, logArea, logURL, networkMgr, storageMgr) {
        this.appName = appName;
        this.appVersion = appVersion;
        this.logLevel = Constants.LOG_LEVEL_DEFAULT;
        this.logArea = logArea;
        this.logURL = logURL;
        this.deviceId = "N/A";
        this.testrunId = "N/A";
        this.testrunPIN = 0;
        this.networkManager = networkMgr;
        this.storageManager = storageMgr;
    }

    setDeviceID(deviceId) {
        this.deviceId = deviceId;
        this.debug(`LogManager:setDeviceID() - "${deviceId}"`);
    }

    setTestRunID(testrunId) {
        this.testrunId = testrunId;
        this.debug(`LogManager:setTestRunID() - "${testrunId}"`);
    }

    setTestRunPIN(testRunPIN) {
        this.testrunPIN = testRunPIN;
        this.debug(`LogManager:setTestRunPIN() - "${testRunPIN}"`);
    }

    setLevel(logLevel) {
        switch (logLevel) {
            case Constants.LOG_LEVEL.OFF:
                this.logLevel = Constants.LOG_LEVEL.OFF;
                break;

            case Constants.LOG_LEVEL.ERROR:
                this.logLevel = Constants.LOG_LEVEL.ERROR;
                break;

            case Constants.LOG_LEVEL.WARNING:
                this.logLevel = Constants.LOG_LEVEL.WARNING;
                break;

            case Constants.LOG_LEVEL.INFO:
                this.logLevel = Constants.LOG_LEVEL.INFO;
                break;

            case Constants.LOG_LEVEL.DEBUG:
                this.logLevel = Constants.LOG_LEVEL.DEBUG;
                break;

            default:
                this.logLevel = Constants.LOG_LEVEL.DEBUG;
                break;
        }
    }

    getDateTime() {
        let d = new Date();
        let dformat = d.toISOString();
        return dformat;
    }

    sendLog(logEntryObj) {
        const _self = this;
        if (_self.logLevel.level >= Constants.LOG_LEVEL.DEBUG.level) {
            console.log(`LogManager:sendLog() - Log POST: ${JSON.stringify(logEntryObj)}`);
        }
        this.networkManager.sendJSON(
            _self.logURL,
            logEntryObj,
            (status, responseText) => {
                // The request has been completed successfully
                if (_self.logLevel.level >= Constants.LOG_LEVEL.DEBUG.level) {
                    console.log(`LogManager:sendLog() - Log POST success: status = ${status}${responseText ? ", " + responseText : ""}`);
                }
            },
            (error) => {
                // There has been an error with the request!
                if (_self.logLevel.level >= Constants.LOG_LEVEL.ERROR.level) {
                    console.error(`Error posting log data: ${error}`);
                }
            }
        );
    }

    showLog(logEntryObj, a344LogEntryObj) {
        if (this.logArea) {
            let logEntry = `[${logEntryObj.aspectLogMsg.logMsgTimestamp}, ${logEntryObj.aspectLogMsg.logLevel}] ${a344LogEntryObj.a344LogEntry.logValue} ${
                a344LogEntryObj.a344LogEntry.logObj ? " ==> " + JSON.stringify(a344LogEntryObj.a344LogEntry.logObj) : ""
            }`;
            this.logArea.value += logEntry + "\r\n";
            this.logArea.scrollTop = this.logArea.scrollHeight;
        }
    }

    consoleLog(logEntryObj, a344LogEntryObj, logLevel) {
        let logEntry = `[${logEntryObj.aspectLogMsg.logMsgTimestamp}, ${logEntryObj.aspectLogMsg.logLevel}] ${a344LogEntryObj.a344LogEntry.logValue} ${
            a344LogEntryObj.a344LogEntry.logObj ? " ==> " + JSON.stringify(a344LogEntryObj.a344LogEntry.logObj) : ""
        }`;
        if (logLevel.level === Constants.LOG_LEVEL.ERROR.level) {
            console.error(logEntry);
        } else {
            console.log(logEntry);
        }
    }

    log(logLevel, value, object) {
        try {
            if (logLevel.level <= this.logLevel.level) {
                let logEntryNo = this.storageManager.getTestRunLogEntryCount();
                // Build the A/344 log entry
                let a344LogEntryObj = {
                    a344LogEntry: {
                        sourceVersion: this.appVersion,
                        deviceId: this.deviceId,
                        testRunId: this.testrunId,
                        testRunPIN: this.testrunPIN,
                        logEntryNo: logEntryNo,
                        logValue: value.escapeSpecialChars(),
                        logObj: object,
                    },
                };
                this.storageManager.setTestRunLogEntryCount(logEntryNo + 1);

                // Build the Aspect log entry wrapper
                let logEntryObj = {
                    aspectLogMsg: {
                        sourceId: this.appName,
                        logLevel: logLevel.name,
                        logMessage: JSON.stringify(a344LogEntryObj),
                        logMsgTimestamp: this.getDateTime(),
                    },
                };
                this.consoleLog(logEntryObj, a344LogEntryObj, logLevel);
                this.showLog(logEntryObj, a344LogEntryObj);
                this.sendLog(logEntryObj);
            }
        } catch (error) {
            console.error(`Error logging data: ${error}`);
        }
    }

    error(value, object) {
        this.log(Constants.LOG_LEVEL.ERROR, value, object);
    }

    warning(value, object) {
        this.log(Constants.LOG_LEVEL.WARNING, value, object);
    }

    info(value, object) {
        this.log(Constants.LOG_LEVEL.INFO, value, object);
    }

    debug(value, object) {
        this.log(Constants.LOG_LEVEL.DEBUG, value, object);
    }
}
