export default class NetworkManager {
    constructor() {}

    sendJSON(url, jsonObj, successCallback, errorCallback) {
        // Sending data in JSON format using POST method
        //
        try {
            if (url) {
                let xhr = new XMLHttpRequest();

                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json");
                const _self = this;
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === XMLHttpRequest.DONE) {
                        var status = xhr.status;
                        if (status === 0 || (status >= 200 && status < 400)) {
                            // The request has been completed successfully
                            if (successCallback) successCallback(status, xhr.responseText);
                        } else {
                            // There has been an error with the request!
                            if (errorCallback) errorCallback(`status = ${status}${xhr.responseText ? ", " + xhr.responseText : ""}`);
                        }
                    }
                };

                xhr.send(JSON.stringify(jsonObj));
            }
        } catch (error) {
            if (errorCallback) errorCallback(`POST error: ${error}`);
        }
    }

    getTestRunData(url, successCallback, errorCallback) {
        // Retrieving PIN/Timestamp data in JSON format using GET method
        // The response is expected to look like:
        // {
        //     "pinResponse": {
        //         "pin": "000W"
        //     }
        // }
        try {
            if (url) {
                let xhr = new XMLHttpRequest();

                xhr.open("GET", url, true);
                //xhr.setRequestHeader("Content-Type", "application/json");
                const _self = this;
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === XMLHttpRequest.DONE) {
                        var status = xhr.status;
                        if (status === 0 || (status >= 200 && status < 400)) {
                            // The request has been completed successfully
                            if (successCallback) {
                                if (successCallback && xhr.responseText) {
                                    let respObj = JSON.parse(xhr.responseText);
                                    successCallback(respObj.pinResponse.pin);
                                } else {
                                    if (errorCallback) errorCallback(`status = ${status}${xhr.responseText ? ", " + xhr.responseText : ""}`);
                                }
                            }
                        } else {
                            // There has been an error with the request!
                            if (errorCallback) errorCallback(`status = ${status}${xhr.responseText ? ", " + xhr.responseText : ""}`);
                        }
                    }
                };

                xhr.send();
            }
        } catch (error) {
            if (errorCallback) errorCallback(`GET error: ${error}`);
        }
    }
}
