/** @format */

// "use strict";
import { ATSCCommands, Constants } from "./A344Test_Constants.js";

class AtscMessagesManager {
    constructor(logMgr, timeout = 30000) {
        this.logManager = logMgr;
        this.socketMessageId = 1;
        this.msgMap = new Map();
        this.astcSocketTimeout = timeout;
        this.init();
    }

    init() {
        const _self = this;
        setInterval(() => {
            _self.msgMap.forEach((element, index) => {
                if (Date.now() - element.epochdate > _self.astcSocketTimeout) {
                    _self.logManager.error("Message timed out", element);
                    _self.removeMsg(index);
                }
            });
        }, _self.astcSocketTimeout);
    }

    getNextSocketId() {
        this.socketMessageId < 2147483647 ? this.socketMessageId++ : (this.socketMessageId = 1);
        return this.socketMessageId;
    }

    addMsg(id, message) {
        let value = new Object();
        value.epochdate = Date.now();
        value.message = message;
        this.msgMap.set(id, value);
    }

    removeMsg(id) {
        let sent = this.msgMap.get(id);
        if (this.msgMap.delete(id)) {
            return sent.message;
        } else {
            // the element must have been removed (expired?)
            return undefined;
        }
    }
}

export default class ATSCSocketService {
    constructor(logMgr, uiMgr) {
        this.logManager = logMgr;
        this.uiManager = uiMgr;
        this.nabSocket = null;
        this.atscMessagesManager = new AtscMessagesManager(logMgr);
        this.messageCallback = null;
        this.notificationCallback = null;

        this.logManager.debug("ATSCSocketService:constructor()");
    }

    /* Socket interaction functions */

    setWebSocketConnection(url, onSocketOpenedFunction, onSocketErrorFunction) {
        const _self = this;
        var wsURL = url + "/atscCmd";
        try {
            _self.logManager.debug("Opening Web Socket Connection..." + wsURL);
            _self.nabSocket = new WebSocket(wsURL);
        } catch (e) {
            _self.logManager.error("Error opening Web Socket" + e.stack);
        }

        _self.nabSocket.onopen = function () {
            _self.onOpen(onSocketOpenedFunction, wsURL);
        };

        // Listen for messages
        _self.nabSocket.onmessage = function (message) {
            _self.onMessage(message);
        };

        _self.nabSocket.onerror = function (event) {
            _self.logManager.info("Web Socket: Unknown Error");
            // onSocketErrorFunction("Unknown Error");
        };

        _self.nabSocket.onclose = function (event) {
            _self.onClose(event, onSocketErrorFunction);
        };

        return _self.nabSocket;
    }

    onOpen(onSocketOpenedFunction, wsURL) {
        this.logManager.debug("Web Socket connection established on " + wsURL);
        onSocketOpenedFunction();
    }

    onMessage(message) {
        const _self = this;
        if (message.data) {
            const messageObj = JSON.parse(message.data);
            _self.logManager.debug("ATSCSocketService:onMessage() - Received message: " + message.data);
            let request;
            let forwardMsg = false;
            let forwardNotification = false;
            if (messageObj.id) {
                request = _self.atscMessagesManager.removeMsg(messageObj.id);
                if (request) {
                    _self.logManager.debug("ATSCSocketService:onMessage() - Message request found", request);
                    forwardMsg = true;
                } else {
                    _self.logManager.warning("ATSCSocketService:onMessage() - Message request NOT found!", messageObj);
                }
                //messageObj.method = request.method;
            } else {
                if (messageObj.method != ATSCCommands.NOTIFY.method) {
                    _self.logManager.error("ATSCSocketService:onMessage() - Unable to parse received message!", messageObj);
                } else {
                    _self.logManager.info("*Notification*", messageObj);

                    forwardNotification = true;
                }
            }
            if (_self.messageCallback) {
                if (forwardMsg) _self.messageCallback(request, messageObj);
            }
            if (_self.notificationCallback) {
                if (forwardNotification) _self.notificationCallback(messageObj);
            }
        } else {
            _self.logManager.warning("ATSCSocketService:onMessage() - Message data not available!", message);
        }
    }

    onClose(event, onSocketErrorFunction) {
        //this.logManager.warning("Web Socket closing");
        if (event.code == 3001) {
            this.logManager.info("Web Socket closed");
        } else {
            const errMsg = `Unable to connect to ${event.currentTarget.url}`;
            //this.logManager.error(errMsg);
            if (onSocketErrorFunction) onSocketErrorFunction(errMsg);
        }
        return null;
    }

    queryServiceId(callbackFn) {
        this.logManager.debug("ATSCSocketService:queryServiceId() - Service ID sending message...");
        this.sendMessage(ATSCCommands.SERVICE_ID, callbackFn);
    }

    queryMediaTime(callbackFn) {
        this.logManager.debug("ATSCSocketService:queryMediaTime() - Media Time sending message...");
        this.sendMessage(ATSCCommands.RMP_MEDIATIME, callbackFn);
    }

    sendMessage(data, callbackFn, timeout_ms) {
        try {
            let message;
            data = data.method ? data : JSON.parse(data);
            if (this.nabSocket.readyState == this.nabSocket.OPEN) {
                this.messageCallback = callbackFn;
                let messageID = this.atscMessagesManager.getNextSocketId();
                message = {
                    jsonrpc: "2.0",
                    method: data.method,
                    id: data.id ? data.id : messageID,
                };
                if (data.params !== undefined) {
                    message["params"] = data.params;
                }
                this.logManager.debug("ATSCSocketService:sendMessage() - ATSC context sending message", message);
                this.atscMessagesManager.addMsg(messageID, message);
                this.nabSocket.send(JSON.stringify(message));

                if (timeout_ms) {
                    // invoke onMessage (w/empty response) when the timeout expires
                    const _self = this;
                    setTimeout(() => {
                        _self.logManager.debug(`Web Socket timeout expired after ${timeout_ms}ms; empty result message returned for message id ${message.id}.`);
                        _self.onMessage({ data: { id: message.id, result: {} } });
                    }, timeout_ms);
                }
            } else {
                this.logManager.warning("Web Socket not in OPEN state; message dropped.");
            }
        } catch (error) {
            this.logManager.error(`Error sending Web Socket message: ${error}`);
        }
    }

    waitForNotification(callbackFn) {
        try {
            if (this.nabSocket.readyState == this.nabSocket.OPEN) {
                this.notificationCallback = callbackFn;
            } else {
                this.logManager.warning("Web Socket not in OPEN state; unable to set notification callback.");
            }
        } catch (error) {
            this.logManager.error(`Error waiting for Web Socket notification: ${error}`);
        }
    }

    stopWaitingForNotifications() {
        this.notificationCallback = null;
    }
}
