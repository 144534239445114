/** @format */

// "use strict";
import { ATSCCommands, Constants } from "./A344Test_Constants.js";
import { Environment } from "./Environment.js";
import * as Utils from "./A344Test_Utils.js";

export default class TestManager {
    constructor(callerID, ctvFacade, uiMgr, logMgr, storageMgr, networkMgr) {
        this.callerID = callerID;
        this.ctvFacade = ctvFacade;
        this.uiManager = uiMgr;
        this.logManager = logMgr;
        this.storageManager = storageMgr;
        this.networkManager = networkMgr;
        this.testContentSections = [];
        this.currentTestRunCount = 0;
        this.currentTestContentSection = 0;
        this.testName = "N/A";
        this.endSession = false;
    }

    toggleEndSession() {
        this.endSession = !this.endSession;
        this.logManager.debug(`TestManager:toggleEndSession(): EndSession = ${this.endSession}`);
        // update GUI to reflect that the EndSession flag is set
        const testRunPIN = this.storageManager.getTestRunPIN();
        this.uiManager.displayInfo(`PIN: ${testRunPIN}`, this.endSession ? "#0f0" : undefined);
    }

    runTests(url, testRunPIN, testRunID, doneCallback) {
        try {
            this.testName = url.substring(url.lastIndexOf("/") + 1);
            this.testContentSections = [];
            const _self = this;
            // url (required), options (optional)
            fetch(url, {
                method: "get",
            })
                .then((response) => response.json())
                .then(function (testScriptObj) {
                    // let's mark this script url as "not-completed", at the end of the script execution (and at the end of the session)
                    // we'll clear this value. This will allow us to check if within the same session this script was
                    // started but not completed (i.e. crash, forces shut-down, etc.)
                    let scriptNotCompletedCount = _self.storageManager.getTestRunScriptNotCompletedCount(url) + 1; // +1 since we're counting this execution as well
                    _self.storageManager.setTestRunScriptNotCompletedCount(url, scriptNotCompletedCount);

                    // every time we load the same script (within the same test session), we'll increase this counter
                    _self.currentTestRunCount = _self.storageManager.getTestRunScriptRunCount(url);
                    _self.storageManager.setTestRunScriptRunCount(url, _self.currentTestRunCount + 1);

                    for (let contentIdx = 0; contentIdx < testScriptObj.A344Test.TestContentSections.length; contentIdx++) {
                        let sectionObj = testScriptObj.A344Test.TestContentSections[contentIdx];
                        const testScriptId_section = `${_self.currentTestRunCount}.${contentIdx + 1}`;
                        _self.testContentSections.push(
                            new TestContentSection(
                                sectionObj,
                                _self.testName,
                                testScriptId_section,
                                url,
                                _self.callerID,
                                _self.ctvFacade,
                                _self.uiManager,
                                _self.logManager,
                                _self.storageManager,
                                _self.networkManager
                            )
                        );
                    }

                    _self.logManager.info(`Test Source`, {
                        testScriptId: `${_self.currentTestRunCount}`,
                        sourceURL: url,
                    });

                    _self.logManager.info(`Test Definition`, {
                        testScriptId: `${_self.currentTestRunCount}`,
                        sourceJSON: testScriptObj,
                    });

                    _self.uiManager.displayInfo(`PIN: ${testRunPIN}`, _self.endSession ? "#0f0" : undefined);

                    _self.execute(url, doneCallback);
                })
                .catch(function (err) {
                    _self.logManager.error(`Error retrieving test file at: ${url} (${err})`);
                    _self.testName = url.substring(url.lastIndexOf("/") + 1);
                    _self.uiManager.displayInfo(`Error loading Test: ${_self.testName}`, "#f00");
                    doneCallback(false);
                });
        } catch (error) {
            this.logManager.error(`Error Running Test Script`, { URL: `${url}`, Error: `${error}` });
        }
    }

    execute(url, doneCallback) {
        const _self = this;

        this.logManager.info(`Test Script Start`, {
            testScriptId: `${this.currentTestRunCount}`,
        });

        this.executeTestContentSections(this.testContentSections[this.currentTestContentSection], (aborted) => {
            if (aborted) {
                _self.uiManager.clearDisplay(true, false, true);
                _self.uiManager.displayTestInfo(`Test Script Aborted!`);

                // reset the value, since the test was aborted by user action
                _self.storageManager.setTestRunScriptNotCompletedCount(url, 0);

                if (doneCallback) doneCallback(aborted);
            } else {
                if (_self.endSession) {
                    // these 3 log entries are required by SAPIS to properly display the Session End data
                    this.logManager.info("Executing Test", {
                        name: "Test Utility: Session End / Reset PIN",
                        testScriptId: "1.1.1",
                        execution: "Selected by User",
                        ctvcvs: "U-0001-01",
                    });
                    this.logManager.info("Executing Test Step", {
                        stepType: "EndOfTest",
                        testScriptId: "1.1.1.1",
                    });

                    // retrieve Test PIN from the log-server
                    _self.networkManager.getTestRunData(
                        Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                        (nextPIN) => {
                            _self.logManager.debug(`Test Session PIN retrieved from log-server`, {
                                Source: "TestManager",
                                PIN_URL: Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                                PIN: nextPIN,
                            });

                            // Avoid showing the entire URL in SAPIS for security reasons
                            //     pinURL: protocol/host
                            let pathArray = Constants.LOG_URLS[Environment.ENVIRONMENT].PIN.split("/");
                            let pinURL = pathArray[0] + "//" + pathArray[2] + "/...";
                            _self.logManager.info("Executing Test Step", {
                                stepType: "SessionEnd",
                                testScriptId: "1.1.1.2",
                                apiCall: { PIN_URL: pinURL },
                                apiResp: { PIN: nextPIN },
                                validation: [
                                    {
                                        validated: true,
                                        validationType: "auto",
                                    },
                                ],
                                result: true,
                            });

                            // clear the cache
                            _self.storageManager.deleteAll();
                            let testRunId = Utils.generateUUID();

                            // store the test run data ==> Pairing
                            _self.storageManager.setTestRunId(testRunId);
                            _self.storageManager.setTestRunPIN(nextPIN);

                            _self.executeTestContentSectionsCompleted(url, doneCallback);
                        },
                        (error) => {
                            _self.logManager.debug(`Error retrieving Test Session PIN from log-server`, {
                                Source: "TestManager",
                                PIN_URL: Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                                Error: error,
                            });

                            // Avoid showing the entire URL in SAPIS for security reasons
                            //     pinURL: protocol/host
                            let pathArray = Constants.LOG_URLS[Environment.ENVIRONMENT].PIN.split("/");
                            let pinURL = pathArray[0] + "//" + pathArray[2] + "/...";
                            _self.logManager.error("Executing Test Step", {
                                stepType: "SessionEnd",
                                testScriptId: "1.1.1.2",
                                apiCall: { PIN_URL: pinURL },
                                apiResp: { error: error },
                                validation: [
                                    {
                                        validated: false,
                                        validationType: "auto",
                                        message: "Unable to retrieve test session PIN",
                                    },
                                ],
                                result: false,
                            });
                            // clear the cache
                            _self.storageManager.deleteAll();

                            _self.executeTestContentSectionsCompleted(url, doneCallback);
                        }
                    );
                } else {
                    _self.executeTestContentSectionsCompleted(url, doneCallback);
                }
            }
        });
    }

    executeTestContentSectionsCompleted(url, doneCallback) {
        // reset the value, since the test completed successfully
        this.storageManager.setTestRunScriptNotCompletedCount(url, 0);

        const _self = this;
        this.uiManager.waitKeypress(
            ["Enter", "Back"],
            (keyCode) => {
                // show test summary
                let testSummary = _self.testSummary();
                _self.uiManager.displayTestInfo(`Test Script Summary`);
                _self.uiManager.displayMessage(testSummary);
                // wait for user input to finish
                _self.uiManager.waitKeypress(
                    ["Enter", "Back"],
                    (keyCode) => {
                        _self.uiManager.clearDisplay(true, false, true);
                        _self.uiManager.displayTestInfo(`Test Script Completed!`);
                        if (doneCallback) doneCallback(false);
                    },
                    `Press "Enter" key to finish...`
                );
            },
            `Tests completed: Press "Enter" key to see a summary...`
        );
    }

    executeTestContentSections(testContentSection, doneCallback) {
        const _self = this;
        if (testContentSection) {
            testContentSection.execute((aborted) => {
                _self.onSectionExecuteDone(aborted, doneCallback);
            });
        } else {
            if (doneCallback) doneCallback();
            _self.currentTestContentSection = 0;
        }
    }

    onSectionExecuteDone(aborted, doneCallback) {
        if (!aborted) {
            if (this.currentTestContentSection < this.testContentSections.length - 1) {
                this.currentTestContentSection++;
                this.executeTestContentSections(this.testContentSections[this.currentTestContentSection], doneCallback);
            } else {
                if (doneCallback) doneCallback(false);
            }
        } else {
            // the user aborted while executing a test... we're done.
            if (doneCallback) doneCallback(aborted);
        }
    }

    testSummary() {
        let summary = "";

        for (let sectionIdx = 0; sectionIdx < this.testContentSections.length; sectionIdx++) {
            let testContentSection = this.testContentSections[sectionIdx];
            summary += `\n\t\tTest Content Section ${sectionIdx + 1} of ${this.testContentSections.length}:\n${testContentSection.testSummary(1)}\n`;
        }

        return summary;
    }
}

class TestContentSection {
    constructor(sectionObj, testScriptName, testScriptId, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr, networkMgr) {
        this.testScriptId = testScriptId;
        this.ctvFacade = ctvFacade;
        this.uiManager = uiMgr;
        this.logManager = logMgr;
        this.storageManager = storageMgr;
        this.testList = [];
        this.currentTest = 0;
        this.serviceId = sectionObj.ServiceID;
        this.mediaTime = {
            startTime: sectionObj.MediaTimeRange ? sectionObj.MediaTimeRange.StartTime : undefined,
            endTime: sectionObj.MediaTimeRange ? sectionObj.MediaTimeRange.EndTime : undefined,
        };

        for (let testIdx = 0; testIdx < sectionObj.Tests.length; testIdx++) {
            let testObj = sectionObj.Tests[testIdx];
            let testScriptId_test = `${this.testScriptId}.${testIdx + 1}`;
            this.testList.push(
                new Test(testObj, testScriptName, testScriptId_test, scriptURL, callerID, this.ctvFacade, this.uiManager, this.logManager, this.storageManager, networkMgr)
            );
        }
    }

    execute(doneCallback) {
        // Execute the content section's tests:
        //  - If the service id matches what's in the section (if defined) AND
        //  - If the current RMP Media Time is within the specified "MediaTimeRange" (if defined)

        const testRunId = this.storageManager.getTestRunId();
        const testRunPIN = this.storageManager.getTestRunPIN();
        this.logManager.setTestRunID(testRunId);
        this.logManager.setTestRunPIN(testRunPIN);

        const _self = this;
        if (this.serviceId && this.mediaTime.startTime && this.mediaTime.endTime) {
            this.ctvFacade.queryServiceId(function (jsonObj) {
                const serviceIdentifier = jsonObj ? (jsonObj.result ? jsonObj.result.service : undefined) : undefined;
                if (_self.serviceId === serviceIdentifier) {
                    _self.ctvFacade.queryMediaTime(function (jsonObj) {
                        const startDate = jsonObj ? (jsonObj.result ? jsonObj.result.startDate : undefined) : undefined;
                        const currentTimeSec = jsonObj ? (jsonObj.result ? jsonObj.result.currentTime : undefined) : undefined;
                        if (startDate && currentTimeSec) {
                            let mediaTimeCurr = Utils.parseISOString(startDate);
                            mediaTimeCurr.setMilliseconds(mediaTimeCurr.getMilliseconds() + currentTimeSec * 1000);
                            let mediaRangeStart = Utils.parseISOString(_self.mediaTime.startTime);
                            let mediaRangeEnd = Utils.parseISOString(_self.mediaTime.endTime);
                            if (mediaTimeCurr >= mediaRangeStart && mediaTimeCurr <= mediaRangeEnd) {
                                // both the service-id and media time range match: execute the test content section
                                _self.logManager.info(`Test Content Section Executing`, {
                                    testScriptId: _self.testScriptId,
                                    matchDataExpected: {
                                        serviceId: _self.serviceId,
                                        startTime: _self.mediaTime.startTime,
                                        endTime: _self.mediaTime.endTime,
                                    },
                                    matchDataActual: {
                                        serviceId: serviceIdentifier,
                                        currentDate: startDate,
                                        currentTime: currentTimeSec,
                                    },
                                });
                                _self.executeTests(doneCallback);
                            } else {
                                // the current media time is outside the specified range: skip the test content section
                                _self.logManager.info(`Test Content Section Skipped (media time out of range)`, {
                                    testScriptId: _self.testScriptId,
                                    matchDataExpected: {
                                        serviceId: _self.serviceId,
                                        startTime: _self.mediaTime.startTime,
                                        endTime: _self.mediaTime.endTime,
                                    },
                                    matchDataActual: {
                                        serviceId: serviceIdentifier,
                                        currentDate: startDate,
                                        currentTime: currentTimeSec,
                                    },
                                });
                                if (doneCallback) doneCallback();
                            }
                        } else {
                            // the current media time was not retrieved: skip the test content section
                            _self.logManager.info(`Test Content Section Skipped (unable to retrieve current media time)`, {
                                testScriptId: _self.testScriptId,
                                matchDataExpected: {
                                    serviceId: _self.serviceId,
                                    startTime: _self.mediaTime.startTime,
                                    endTime: _self.mediaTime.endTime,
                                },
                                matchDataActual: {
                                    serviceId: serviceIdentifier,
                                    currentDate: startDate,
                                    currentTime: currentTimeSec,
                                },
                            });
                            if (doneCallback) doneCallback();
                        }
                    });
                } else {
                    // service-id mismatch, skip the test content section.
                    _self.logManager.info(`Test Content Section Skipped (service-id mismatch)`, {
                        testScriptId: _self.testScriptId,
                        matchDataExpected: {
                            serviceId: _self.serviceId,
                            startTime: _self.mediaTime.startTime,
                            endTime: _self.mediaTime.endTime,
                        },
                        matchDataActual: {
                            serviceId: serviceIdentifier,
                            currentDate: undefined,
                            currentTime: undefined,
                        },
                    });
                    if (doneCallback) doneCallback();
                }
            });
        } else if (this.serviceId) {
            // make sure the service id for the content playing matches
            this.ctvFacade.queryServiceId(function (jsonObj) {
                const serviceIdentifier = jsonObj ? (jsonObj.result ? jsonObj.result.service : undefined) : undefined;
                if (_self.serviceId === serviceIdentifier) {
                    _self.logManager.info(`Test Content Section Executing`, {
                        testScriptId: _self.testScriptId,
                        matchDataExpected: {
                            serviceId: _self.serviceId,
                            startTime: _self.mediaTime.startTime,
                            endTime: _self.mediaTime.endTime,
                        },
                        matchDataActual: {
                            serviceId: serviceIdentifier,
                            currentDate: undefined,
                            currentTime: undefined,
                        },
                    });
                    _self.executeTests(doneCallback);
                } else {
                    // service-id mismatch, skip the test content section.
                    _self.logManager.info(`Test Content Section Skipped (service-id mismatch)`, {
                        testScriptId: _self.testScriptId,
                        matchDataExpected: {
                            serviceId: _self.serviceId,
                            startTime: _self.mediaTime.startTime,
                            endTime: _self.mediaTime.endTime,
                        },
                        matchDataActual: {
                            serviceId: serviceIdentifier,
                            currentDate: undefined,
                            currentTime: undefined,
                        },
                    });
                    if (doneCallback) doneCallback();
                }
            });
        } else if (this.mediaTime.startTime && this.mediaTime.endTime) {
            _self.ctvFacade.queryMediaTime(function (jsonObj) {
                const startDate = jsonObj ? (jsonObj.result ? jsonObj.result.startDate : undefined) : undefined;
                const currentTimeSec = jsonObj ? (jsonObj.result ? jsonObj.result.currentTime : undefined) : undefined;
                if (startDate && currentTimeSec) {
                    let mediaTimeCurr = Utils.parseISOString(startDate);
                    mediaTimeCurr.setMilliseconds(mediaTimeCurr.getMilliseconds() + currentTimeSec * 1000);
                    let mediaRangeStart = Utils.parseISOString(_self.mediaTime.startTime);
                    let mediaRangeEnd = Utils.parseISOString(_self.mediaTime.endTime);
                    if (mediaTimeCurr >= mediaRangeStart && mediaTimeCurr <= mediaRangeEnd) {
                        // the media time range match: execute the test content section
                        _self.logManager.info(`Test Content Section Executing`, {
                            testScriptId: _self.testScriptId,
                            matchDataExpected: {
                                serviceId: _self.serviceId,
                                startTime: _self.mediaTime.startTime,
                                endTime: _self.mediaTime.endTime,
                            },
                            matchDataActual: {
                                serviceId: undefined,
                                currentDate: startDate,
                                currentTime: currentTimeSec,
                            },
                        });
                        _self.executeTests(doneCallback);
                    } else {
                        // the current media time is outside the specified range: skip the test content section
                        _self.logManager.info(`Test Content Section Skipped (media time out of range)`, {
                            testScriptId: _self.testScriptId,
                            matchDataExpected: {
                                serviceId: _self.serviceId,
                                startTime: _self.mediaTime.startTime,
                                endTime: _self.mediaTime.endTime,
                            },
                            matchDataActual: {
                                serviceId: undefined,
                                currentDate: startDate,
                                currentTime: currentTimeSec,
                            },
                        });
                        if (doneCallback) doneCallback();
                    }
                } else {
                    // the current media time was not retrieved: skip the test content section
                    _self.logManager.info(`Test Content Section Skipped (unable to retrieve current media time)`, {
                        testScriptId: _self.testScriptId,
                        matchDataExpected: {
                            serviceId: _self.serviceId,
                            startTime: _self.mediaTime.startTime,
                            endTime: _self.mediaTime.endTime,
                        },
                        matchDataActual: {
                            serviceId: undefined,
                            currentDate: startDate,
                            currentTime: currentTimeSec,
                        },
                    });
                    if (doneCallback) doneCallback();
                }
            });
        } else {
            // Neither service-id nor the media-time range was specified: execute the test content section.
            this.logManager.info(`Test Content Section Executing`, {
                testScriptId: this.testScriptId,
                matchDataExpected: {
                    serviceId: this.serviceId,
                    startTime: this.mediaTime.startTime,
                    endTime: this.mediaTime.endTime,
                },
                matchDataActual: {
                    serviceId: undefined,
                    currentDate: undefined,
                    currentTime: undefined,
                },
            });
            this.executeTests(doneCallback);
        }
    }

    executeTests(doneCallback) {
        // are there any 'selectable' tests
        let selectable = false;
        let testNameList = [];
        for (let testIdx = 0; testIdx < this.testList.length; testIdx++) {
            if (this.testList[testIdx].selectable) selectable = true;
            testNameList.push(this.testList[testIdx].name);
        }
        if (selectable && this.testList.length > 1) {
            this.uiManager.clearDisplay(true, true, false);
            // if any of the tests is marked as 'selectable', then ask the user to choose
            const _self = this;
            this.uiManager.waitForSelection(
                testNameList,
                (selectionIdx, abort) => {
                    if (abort) {
                        // the user aborted instead of selecting the test to run... we're done.
                        if (doneCallback) doneCallback(abort);
                    } else {
                        let selectedTest = _self.testList[selectionIdx];

                        // _self.logManager.info(`User selected test: "${selectedTest.name}"`);

                        _self.testList = [];
                        _self.testList.push(selectedTest);
                        _self.executeTest(_self.testList[_self.currentTest], false, doneCallback);
                    }
                },
                `Select the test to run and press "Enter" to continue...`
            );
        } else {
            // run all tests
            this.executeTest(this.testList[this.currentTest], true, doneCallback);
        }
    }

    executeTest(test, auto, doneCallback) {
        const _self = this;
        if (test) {
            test.execute(auto, (aborted) => {
                _self.onTestExecuteDone(aborted, doneCallback);
            });
        } else {
            if (doneCallback) doneCallback();
            _self.currentTest = 0;
        }
    }

    onTestExecuteDone(aborted, doneCallback) {
        if (!aborted) {
            if (this.currentTest < this.testList.length - 1) {
                this.currentTest++;
                this.executeTest(this.testList[this.currentTest], true, doneCallback);
            } else {
                if (doneCallback) doneCallback(false);
            }
        } else {
            // the user aborted while executing test steps... we're done.
            if (doneCallback) doneCallback(aborted);
        }
    }

    testSummary(level = 0) {
        let summary = "";

        for (let testIdx = 0; testIdx < this.testList.length; testIdx++) {
            let test = this.testList[testIdx];
            summary += test.testSummary(level + 1);
        }

        return summary;
    }
}

class TestStepList {
    constructor(name, testScriptId, testStepsObj, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr, networkMgr) {
        this.ctvFacade = ctvFacade;
        this.uiManager = uiMgr;
        this.logManager = logMgr;
        this.storageManager = storageMgr;
        this.name = name;
        this.testScriptId = testScriptId;
        this.testSteps = [];
        this.currentStep = 0;

        let stepFactory = new StepFactory(this.ctvFacade, this.uiManager, this.logManager, this.storageManager, networkMgr);
        for (let stepIdx = 0; stepIdx < testStepsObj.length; stepIdx++) {
            let testScriptId_step = `${this.testScriptId}.${stepIdx + 1}`;
            let testStep = stepFactory.createTestStep(testStepsObj[stepIdx], testScriptId_step, scriptURL, callerID);
            if (testStep) this.testSteps.push(testStep);
        }
    }

    executeTestSteps(testStep, doneCallback) {
        const _self = this;
        if (testStep) {
            testStep.execute((aborted) => {
                _self.onTestStepExecuteDone(doneCallback, aborted);
            });
        } else {
            if (doneCallback) doneCallback(false);
            _self.currentStep = 0;
        }
    }

    onTestStepExecuteDone(doneCallback, aborted) {
        if (!aborted && this.currentStep < this.testSteps.length - 1) {
            this.currentStep++;
            this.executeTestSteps(this.testSteps[this.currentStep], doneCallback);
        } else {
            if (doneCallback) doneCallback(aborted);
        }
    }

    testSummary(level = 0) {
        let indent = "";
        for (let levelIdx = 0; levelIdx < level; levelIdx++) {
            indent += "\t\t";
        }
        let summary = `${indent}${this.name}:\n`;

        for (let stepIdx = 0; stepIdx < this.testSteps.length; stepIdx++) {
            if (stepIdx > 0) summary += "\n";
            let testStep = this.testSteps[stepIdx];
            summary += `${indent}\t\tStep ${stepIdx + 1} of ${this.testSteps.length}: ${testStep.getTestResults(level + 1)}`;
        }

        return summary;
    }
}
class Test extends TestStepList {
    constructor(testObj, testScriptName, testScriptId, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr, networkMgr) {
        super(testObj.Name, testScriptId, testObj.Steps, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr, networkMgr);
        this.ctvcvs = testObj.CTVCVS;
        this.selectable = testObj.Selectable;
        this.testScriptName = testScriptName;
    }

    execute(auto, doneCallback) {
        const testRunId = this.storageManager.getTestRunId();
        const testRunPIN = this.storageManager.getTestRunPIN();
        this.logManager.setTestRunID(testRunId);
        this.logManager.setTestRunPIN(testRunPIN);

        this.logManager.info("Executing Test", {
            name: this.name,
            testScriptId: this.testScriptId,
            execution: auto ? "Auto" : "Selected by User",
            ctvcvs: this.ctvcvs,
        });

        this.uiManager.displayInfo(`PIN: ${testRunPIN}`, this.endSession ? "#0f0" : undefined);

        this.uiManager.displayTestInfo(`Test: "${this.name}"`);
        this.executeTestSteps(this.testSteps[this.currentStep], doneCallback);
    }
}

class StepFactory {
    constructor(ctvFacade, uiMgr, logMgr, storageMgr, networkMgr) {
        this.ctvFacade = ctvFacade;
        this.uiMgr = uiMgr;
        this.logMgr = logMgr;
        this.networkMgr = networkMgr;
        this.storageMgr = storageMgr;
    }
    createTestStep(stepObj, testScriptId, scriptURL, callerID) {
        switch (stepObj.stepType) {
            case "APICall":
                return new StepAPICall(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "KeyChoice":
                return new StepKeyChoice(stepObj, testScriptId, scriptURL, callerID, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "KeyWait":
                return new StepKeyWait(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "TimedWait":
                return new StepTimedWait(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "ExpectNotify":
                return new StepExpectNotify(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "Log":
                return new StepLog(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "LogMessage":
                return new StepLogMessage(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "Display":
                return new StepDisplay(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "DisplaySize":
                return new StepDisplaySize(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "AppStatus":
                return new StepAppStatus(stepObj, testScriptId, scriptURL, callerID, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "Validate":
                return new StepValidate(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "APICallBranch":
                return new StepAPICallBranch(stepObj, testScriptId, callerID, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "EndOfTest":
                return new StepEndOfTest(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "SessionStart":
                return new StepSessionStart(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr);
                break;

            case "SessionEnd":
                return new StepSessionEnd(stepObj, testScriptId, this.ctvFacade, this.uiMgr, this.logMgr, this.storageMgr, this.networkMgr);
                break;

            default:
                break;
        }
    }
}

class TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, testResults) {
        this.stepObj = stepObj;
        this.testScriptId = testScriptId;
        this.ctvFacade = ctvFacade;
        this.uiManager = uiMgr;
        this.logManager = logMgr;
        this.storageManager = storageMgr;
        this.testResults = testResults;
    }

    getTestResults() {
        return this.testResults ? this.testResults : "N/A";
    }
}

class ValidatingTestStep extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, testResults) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, testResults);
        this.currentValidationTestIdx = 0;
        this.validationTestCount = 0;
        this.validationTestSuccessCount = 0;
        this.validationObj = [];
    }

    validate(rpcCall, jsonObj, doneCallback, logValidation = true) {
        this.validationTestCount = 0;
        this.validationTestSuccessCount = 0;
        this.validationObj = [];

        // the JSON object can be either an RPC response (use the result) OR a notification (use the params)
        let jsonValidationParams = jsonObj.result ? jsonObj.result : jsonObj.params;
        if (!jsonValidationParams) jsonValidationParams = jsonObj;

        if (jsonObj) {
            const _self = this;
            this.doValidationTest(this.currentValidationTestIdx, jsonValidationParams, (aborted, validationDetails) => {
                if (aborted && doneCallback) {
                    if (validationDetails) _self.validationObj.push(validationDetails);
                    let stepValidated = this.validationTestSuccessCount === this.validationTestCount;
                    if (logValidation)
                        _self.logManager.info("Executing Test Step", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            action: "Validate",
                            apiCall: rpcCall,
                            apiResp: jsonObj,
                            validation: _self.validationObj,
                            result: stepValidated,
                        });
                    doneCallback(aborted, false, _self.validationObj);
                } else {
                    _self.onValidationTestDone(jsonValidationParams, validationDetails, () => {
                        let stepValidated = this.validationTestSuccessCount === this.validationTestCount;
                        if (logValidation)
                            _self.logManager.info("Executing Test Step", {
                                stepType: _self.stepObj.stepType,
                                testScriptId: _self.testScriptId,
                                ctvcvs: _self.stepObj.CTVCVS,
                                action: "Validate",
                                apiCall: rpcCall,
                                apiResp: jsonObj,
                                validation: _self.validationObj,
                                result: stepValidated,
                            });
                        doneCallback(false, stepValidated, _self.validationObj);
                    });
                }
            });
        }
    }

    onValidationTestDone(jsonValidationParams, validationDetails, allValidationTestsDoneCallback) {
        if (validationDetails) this.validationObj.push(validationDetails);

        if (this.currentValidationTestIdx < this.stepObj.Validation.length - 1) {
            this.currentValidationTestIdx++;
            const _self = this;
            this.doValidationTest(this.currentValidationTestIdx, jsonValidationParams, (aborted, validationDetails) => {
                if (aborted && doneCallback) {
                    if (validationDetails) _self.validationObj.push(validationDetails);
                    doneCallback(aborted, false);
                } else {
                    _self.onValidationTestDone(jsonValidationParams, validationDetails, allValidationTestsDoneCallback);
                }
            });
        } else {
            if (allValidationTestsDoneCallback) allValidationTestsDoneCallback();
        }
    }

    doValidationTest(valIdx, jsonValidationParams, validationTestDoneCallback) {
        let aborted = false;
        let validationDetails;
        let validationTestSuccess = false;
        if (this.stepObj.Validation[valIdx].Range) {
            this.validationTestCount++;
            const rangeParam = this.stepObj.Validation[valIdx].Range.param;
            const rangeMin = this.stepObj.Validation[valIdx].Range.Min;
            const rangeMax = this.stepObj.Validation[valIdx].Range.Max;
            if (!jsonValidationParams.error) {
                if (rangeParam === "currentTimeUTC") {
                    validationTestSuccess = false;
                    // SPECIAL CASE: If the API Identifier is Query RMP Media Time and the ParameterName is currentTimeUTC,
                    // then the Range Test shall be applied to the sum of the startTime and currentTime fields.

                    // Example:
                    //
                    // "Validation": [
                    //     {
                    //         "Name": "RMP Media Time UTC Range Test",
                    //         "Range": {
                    //             "param": "currentTimeUTC",
                    //             "Min": "2021-05-18T22:13:44Z",
                    //             "Max": "2021-05-18T23:13:44Z"
                    //         }
                    //     }
                    const jsonDate = jsonValidationParams["startDate"];
                    const jsonTime = jsonValidationParams["currentTime"];
                    if (jsonDate && jsonTime) {
                        // assume ISO date/time
                        let dateMin = Utils.parseISOString(rangeMin);
                        let dateMax = Utils.parseISOString(rangeMax);
                        let dateJson = Utils.parseISOString(jsonDate);
                        dateJson.setMilliseconds(dateJson.getMilliseconds() + jsonTime * 1000);
                        if (dateJson.getTime() >= dateMin.getTime() && dateJson.getTime() <= dateMax.getTime()) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    }
                    if (validationTestSuccess) {
                        validationDetails = {
                            validated: validationTestSuccess,
                            validationType: "range",
                            param: rangeParam,
                            expected: {
                                min: rangeMin,
                                max: rangeMax,
                            },
                            actual: {
                                date: jsonDate,
                                time: jsonTime,
                            },
                        };
                    } else {
                        validationDetails = {
                            validated: validationTestSuccess,
                            message: this.stepObj.Validation[valIdx].FailMessage,
                            validationType: "range",
                            param: rangeParam,
                            expected: {
                                min: rangeMin,
                                max: rangeMax,
                            },
                            actual: {
                                date: jsonDate,
                                time: jsonTime,
                            },
                        };
                    }
                } else {
                    validationTestSuccess = false;
                    // Example:
                    //
                    // "Validation": [
                    //     {
                    //         "Name": "RMP Media Time Date Range Test",
                    //         "Range": {
                    //             "param": "startDate",
                    //             "Min": "2021-05-18T22:13:44Z",
                    //             "Max": "2021-05-18T23:13:44Z"
                    //         }
                    //     }
                    // ]
                    let jsonVal = jsonValidationParams[rangeParam];
                    if (jsonVal != undefined) {
                        if (typeof jsonVal == "number") {
                            if (jsonVal >= rangeMin && jsonVal <= rangeMax) {
                                this.validationTestSuccessCount++;
                                validationTestSuccess = true;
                            }
                        } else if (typeof jsonVal == "string") {
                            // assume ISO date/time
                            let dateMin = Utils.parseISOString(rangeMin);
                            let dateMax = Utils.parseISOString(rangeMax);
                            let dateJson = Utils.parseISOString(jsonVal);
                            if (dateJson.getTime() >= dateMin.getTime() && dateJson.getTime() <= dateMax.getTime()) {
                                validationTestSuccess = true;
                            }
                        }
                    } else {
                        jsonVal = "N/A";
                    }
                    if (validationTestSuccess) {
                        validationDetails = {
                            validated: validationTestSuccess,
                            validationType: "range",
                            param: rangeParam,
                            expected: {
                                min: rangeMin,
                                max: rangeMax,
                            },
                            actual: {
                                val: jsonVal,
                            },
                        };
                    } else {
                        validationDetails = {
                            validated: validationTestSuccess,
                            message: this.stepObj.Validation[valIdx].FailMessage,
                            validationType: "range",
                            param: rangeParam,
                            expected: {
                                min: rangeMin,
                                max: rangeMax,
                            },
                            actual: {
                                val: jsonVal,
                            },
                        };
                    }
                }
            } else {
                validationDetails = {
                    validated: false,
                    message: this.stepObj.Validation[valIdx].FailMessage,
                    validationType: "range",
                    param: rangeParam,
                    expected: {
                        min: rangeMin,
                        max: rangeMax,
                    },
                    actual: {
                        val: undefined,
                    },
                };
            }
            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else if (this.stepObj.Validation[valIdx].Match) {
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "25% Scaled Position Validation Test",
            //         "FailMessage": "Failed Setting RMP URL - Stop",
            //         "Match": {}
            //     },
            //     {
            //         "Name": "ServiceId Match Test",
            //         "Match": {
            //             "param": "service",
            //             "values": ["https://doi.org/10.5239/8A23-2B0B"]
            //         }
            //     }
            // ]

            if (!this.stepObj.Validation[valIdx].Match.param) {
                // Example:
                //
                // "Validation": [
                //     {
                //         "Name": "25% Scaled Position Validation Test",
                //         "FailMessage": "Failed Setting RMP URL - Stop",
                //         "Match": {}
                //     }
                // ]

                const matchVal = this.stepObj.Validation[valIdx].Match;
                const jsonVal = jsonValidationParams;
                // an empty param value means that this validation test is successful when the
                // A/344 API returns an empty object in the "result"
                if (this.isEqual(jsonVal, matchVal)) {
                    this.validationTestSuccessCount++;
                    validationDetails = {
                        validated: true,
                        validationType: "match",
                        param: "result",
                        expected: matchVal,
                        actual: jsonVal,
                    };
                } else {
                    validationDetails = {
                        validated: false,
                        message: this.stepObj.Validation[valIdx].FailMessage,
                        validationType: "match",
                        param: "result",
                        expected: matchVal,
                        actual: jsonVal,
                    };
                }
            } else if (this.stepObj.Validation[valIdx].Match.values) {
                // Example:
                //
                // "Validation": [
                //     {
                //         "Name": "ServiceId Match Test",
                //         "Match": {
                //             "param": "service",
                //             "values": ["https://doi.org/10.5239/8A23-2B0B"]
                //         }
                //     }
                // ]

                const matchParam = this.stepObj.Validation[valIdx].Match.param;
                const matchVals = this.stepObj.Validation[valIdx].Match.values;
                let jsonVal = jsonValidationParams[matchParam];
                if (!jsonValidationParams.error) {
                    validationTestSuccess = false;
                    if (jsonVal != undefined) {
                        for (let valIdx = 0; valIdx < matchVals.length; valIdx++) {
                            // if (jsonVal === matchVals[valIdx]) {
                            // using deep equality check to compare the two values (which can be JSON objects)
                            if (this.isEqual(jsonVal, matchVals[valIdx])) {
                                this.validationTestSuccessCount++;
                                validationTestSuccess = true;
                                break; // matching any of the values is considered a success for this validation test
                            }
                        }
                    } else {
                        jsonVal = "N/A";
                    }
                    if (validationTestSuccess) {
                        validationDetails = {
                            validated: validationTestSuccess,
                            validationType: "match",
                            param: matchParam,
                            expected: matchVals,
                            actual: jsonVal,
                        };
                    } else {
                        validationDetails = {
                            validated: validationTestSuccess,
                            message: this.stepObj.Validation[valIdx].FailMessage,
                            validationType: "match",
                            param: matchParam,
                            expected: matchVals,
                            actual: jsonVal,
                        };
                    }
                } else {
                    validationDetails = {
                        validated: false,
                        message: this.stepObj.Validation[valIdx].FailMessage,
                        validationType: "match",
                        param: matchParam,
                        expected: matchVals,
                        actual: "N/A",
                    };
                }
            } else if (this.stepObj.Validation[valIdx].Match.partialText) {
                // Example:
                //
                // "Validation": [
                //     {
                //         "Name": "ServiceId Match Test",
                //         "Match": {
                //             "param": "service",
                //             "partialText": "https://doi.org"
                //         }
                //     }
                // ]
                const matchParam = this.stepObj.Validation[valIdx].Match.param;
                const matchVal = this.stepObj.Validation[valIdx].Match.partialText;
                let jsonVal = jsonValidationParams[matchParam];
                if (!jsonValidationParams.error) {
                    validationTestSuccess = false;
                    if (jsonVal != undefined) {
                        if (jsonVal.includes(matchVal)) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    } else {
                        jsonVal = "N/A";
                    }
                    if (validationTestSuccess) {
                        validationDetails = {
                            validated: validationTestSuccess,
                            validationType: "match_partial",
                            param: matchParam,
                            expected: matchVal,
                            actual: jsonVal,
                        };
                    } else {
                        validationDetails = {
                            validated: validationTestSuccess,
                            message: this.stepObj.Validation[valIdx].FailMessage,
                            validationType: "match_partial",
                            param: matchParam,
                            expected: matchVal,
                            actual: jsonVal,
                        };
                    }
                } else {
                    validationDetails = {
                        validated: false,
                        message: this.stepObj.Validation[valIdx].FailMessage,
                        validationType: "match_partial",
                        param: matchParam,
                        expected: matchVal,
                        actual: "N/A",
                    };
                }
            } else if (this.stepObj.Validation[valIdx].Match.partialObj) {
                // Example:
                //
                // "Validation": [
                //     {
                //         "Name": "Query Device Info Validation Match Test",
                //         "FailMessage": "Failed aspectVersion Validation",
                //         "Match": {
                //             "param": "deviceInfo",
                //             "partialObj": { "param": "us.aspect:properties" }
                //         }
                //     }
                // ]
                const matchParam = this.stepObj.Validation[valIdx].Match.param;
                const matchObj = this.stepObj.Validation[valIdx].Match.partialObj;
                let jsonVal = jsonValidationParams[matchParam];
                if (!jsonValidationParams.error) {
                    validationTestSuccess = false;
                    if (jsonVal != undefined) {
                        if (jsonVal[matchObj.param] != undefined) {
                            // the partialObj.param was found
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    } else {
                        jsonVal = {};
                    }
                    if (validationTestSuccess) {
                        validationDetails = {
                            validated: validationTestSuccess,
                            validationType: "match_partial",
                            param: matchParam,
                            expected: matchObj,
                            actual: jsonVal,
                        };
                    } else {
                        validationDetails = {
                            validated: validationTestSuccess,
                            message: this.stepObj.Validation[valIdx].FailMessage,
                            validationType: "match_partial",
                            param: matchParam,
                            expected: matchObj,
                            actual: jsonVal,
                        };
                    }
                } else {
                    validationDetails = {
                        validated: false,
                        message: this.stepObj.Validation[valIdx].FailMessage,
                        validationType: "match_partial",
                        param: matchParam,
                        expected: matchObj,
                        actual: {},
                    };
                }
            }
            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else if (this.stepObj.Validation[valIdx].Manual) {
            validationTestSuccess = false;
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "RMP Media Time Manual Test",
            //         "Manual": {
            //             "Prompt": "Make a selection",
            //             "Success": [
            //                 { "Label": "< 250ms", "Keycode": "ArrowUp" },
            //                 { "Label": "250-500ms", "Keycode": "ArrowDown" },
            //                 { "Label": "500ms-1s", "Keycode": "ArrowLeft" },
            //                 { "Label": "> 1s", "Keycode": "ArrowRight" }
            //             ],
            //             "Fail": [{ "Label": "No Reading", "Keycode": "Select/Enter" }]
            //         },
            //         "FailMessage": "Manual Validation Failed"
            //     }
            // ]
            const _self = this;
            setTimeout(() => {
                const userPrompt = _self.stepObj.Validation[valIdx].Manual.Prompt;
                const labelKeySuccessArray = _self.stepObj.Validation[valIdx].Manual.Success;
                const labelKeyFailArray = _self.stepObj.Validation[valIdx].Manual.Fail;
                const labelKeyInputArray = labelKeySuccessArray.concat(labelKeyFailArray);

                let keySuccessArray = [];
                for (let idx = 0; idx < labelKeySuccessArray.length; idx++) {
                    keySuccessArray.push(labelKeySuccessArray[idx].Keycode);
                }
                let keyFailArray = [];
                for (let idx = 0; idx < labelKeyFailArray.length; idx++) {
                    keyFailArray.push(labelKeyFailArray[idx].Keycode);
                }
                let keyInputArray = [];
                let manualChoicesStr = "";
                for (let idx = 0; idx < labelKeyInputArray.length; idx++) {
                    keyInputArray.push(labelKeyInputArray[idx].Keycode);
                    manualChoicesStr += `${labelKeyInputArray[idx].Keycode}:\t${labelKeyInputArray[idx].Label}\n`;
                }

                let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                _self.uiManager.displayMessage(manualChoicesStr, undefined, sessionHasFailure);

                _self.uiManager.waitKeypress(
                    keyInputArray.concat("Back"),
                    (keyCode) => {
                        if (keySuccessArray.includes(keyCode)) {
                            _self.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        } else if (keyFailArray.includes(keyCode)) {
                            validationTestSuccess = false;
                        } else {
                            // Back
                            aborted = true;
                        }

                        if (validationTestSuccess) {
                            validationDetails = {
                                validated: validationTestSuccess,
                                validationType: "manual",
                                prompt: userPrompt,
                                expected: labelKeyInputArray,
                                actual: keyCode,
                            };
                        } else {
                            validationDetails = {
                                validated: validationTestSuccess,
                                message: _self.stepObj.Validation[valIdx].FailMessage,
                                validationType: "manual",
                                prompt: userPrompt,
                                expected: labelKeyInputArray,
                                actual: keyCode,
                            };
                        }
                        if (validationTestDoneCallback) {
                            validationTestDoneCallback(aborted, validationDetails);
                        }
                    },
                    `${userPrompt}`
                );
            }, this.stepObj.Validation[valIdx].Manual.PromptDelay);
        } else if (this.stepObj.Validation[valIdx].Error) {
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "Rating Level Error Validation Test",
            //         "Error": {
            //             "code": -9,
            //             "message": ["A/344 API Not Yet Supported!"]
            //         }
            //     }
            // ]
            const errorExpected = this.stepObj.Validation[valIdx].Error.expected != undefined ? this.stepObj.Validation[valIdx].Error.expected : true;
            const errorCode = this.stepObj.Validation[valIdx].Error.code != undefined ? this.stepObj.Validation[valIdx].Error.code : undefined;
            const errorMessage = this.stepObj.Validation[valIdx].Error.message != undefined ? this.stepObj.Validation[valIdx].Error.message : undefined;
            if (jsonValidationParams.error) {
                validationTestSuccess = false;
                let jsonErrCode = jsonValidationParams.error.code;
                let jsonErrMsg = jsonValidationParams.error.message;
                if (errorExpected) {
                    if (errorCode && errorMessage) {
                        if (errorCode === jsonErrCode && errorMessage === jsonErrMsg) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    } else if (errorCode) {
                        if (errorCode === jsonErrCode) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    } else if (errorMessage) {
                        if (errorMessage === jsonErrMsg) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    } else {
                        // if neither the error code or message was specified for a match
                        // by default the validation is considered a success
                        this.validationTestSuccessCount++;
                        validationTestSuccess = true;
                    }
                }
                if (validationTestSuccess) {
                    validationDetails = {
                        validated: validationTestSuccess,
                        validationType: "error",
                        expected: {
                            code: errorCode ? errorCode : errorExpected ? "<any>" : undefined,
                            message: errorMessage ? errorMessage : errorExpected ? "<any>" : undefined,
                        },
                        actual: {
                            code: jsonErrCode,
                            message: jsonErrMsg,
                        },
                    };
                } else {
                    validationDetails = {
                        validated: validationTestSuccess,
                        message: this.stepObj.Validation[valIdx].FailMessage,
                        validationType: "error",
                        expected: {
                            code: errorCode ? errorCode : errorExpected ? "<any>" : undefined,
                            message: errorMessage ? errorMessage : errorExpected ? "<any>" : undefined,
                        },
                        actual: {
                            code: jsonErrCode,
                            message: jsonErrMsg,
                        },
                    };
                }
            } else {
                if (errorExpected) {
                    // the API returned success when an error response was expected: fail validation
                    validationDetails = {
                        validated: false,
                        message: this.stepObj.Validation[valIdx].FailMessage,
                        validationType: "error",
                        expected: {
                            code: errorCode ? errorCode : errorExpected ? "<any>" : undefined,
                            message: errorMessage ? errorMessage : errorExpected ? "<any>" : undefined,
                        },
                        actual: {
                            code: undefined,
                            message: undefined,
                        },
                    };
                } else {
                    // the API returned success as expected: pass validation
                    this.validationTestSuccessCount++;
                    validationDetails = {
                        validated: true,
                        validationType: "error",
                        expected: {
                            code: errorCode ? errorCode : errorExpected ? "<any>" : undefined,
                            message: errorMessage ? errorMessage : errorExpected ? "<any>" : undefined,
                        },
                        actual: {
                            code: undefined,
                            message: undefined,
                        },
                    };
                }
            }
            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else if (this.stepObj.Validation[valIdx].Status) {
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "App Forced Closed Validation Test",
            //         "FailMessage": "The App Status Validation Test failed",
            //         "Status": {
            //             "param": "AppForcedClose",
            //             "expected": false
            //         }
            //     },
            //     {
            //         "Name": "App Hidden Validation Test",
            //         "FailMessage": "The App Status Validation Test failed",
            //         "Status": {
            //             "param": "AppHidden",
            //             "expected": false
            //         }
            //     },
            //     {
            //         "Name": "App Running Validation Test",
            //         "FailMessage": "The test script is unexpectedly still running.",
            //         "Status": {
            //             "param": "AppRunning",
            //             "expected": false
            //         }
            //     },
            //     {
            //         "Name": "App Caller ID Validation Test",
            //         "FailMessage": "The App ID for the callerIDQuery does not match",
            //         "Status": {
            //             "param": "AppCallerID",
            //             "expected": "pbs.org/kids/1"
            //         }
            //     }
            // ]

            const statusParam = this.stepObj.Validation[valIdx].Status.param;
            if (statusParam === "AppForcedClose") {
                validationTestSuccess = false;
                const scriptStatusURL = this.stepObj.Validation[valIdx].Status.url;
                let actualForcedClose;
                let expectedForcedClose;
                for (let statusIdx = 0; statusIdx < jsonValidationParams.appForcedClosed.length; statusIdx++) {
                    if (jsonValidationParams.appForcedClosed[statusIdx].url === scriptStatusURL) {
                        actualForcedClose = jsonValidationParams.appForcedClosed[statusIdx].closed;
                        expectedForcedClose = this.stepObj.Validation[valIdx].Status.expected;
                        if (actualForcedClose === expectedForcedClose) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                        break; // found the url: done
                    } else {
                        actualForcedClose = undefined;
                        expectedForcedClose = undefined;
                    }
                }
                // if we detect that the application was unexpectedly closed when running this script previously,
                // then we log the status entry and abort (this will also cause the PIN to change)
                // if (actualForcedClose) aborted = true;
                let failedMessage;
                if (!validationTestSuccess) {
                    failedMessage = this.stepObj.Validation[valIdx].FailMessage;
                }

                validationDetails = {
                    validated: validationTestSuccess,
                    message: failedMessage,
                    validationType: "status",
                    param: statusParam,
                    url: scriptStatusURL,
                    expected: expectedForcedClose,
                    actual: actualForcedClose,
                };
            } else if (statusParam === "AppHidden") {
                validationTestSuccess = false;
                const actualHidden = jsonValidationParams.appHidden;
                const expectedHidden = this.stepObj.Validation[valIdx].Status.expected;
                if (actualHidden === expectedHidden) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
                let failedMessage;
                if (!validationTestSuccess) {
                    failedMessage = this.stepObj.Validation[valIdx].FailMessage;
                }
                validationDetails = {
                    validated: validationTestSuccess,
                    message: failedMessage,
                    validationType: "status",
                    param: statusParam,
                    expected: expectedHidden,
                    actual: actualHidden,
                };
            } else if (statusParam === "AppFocus") {
                validationTestSuccess = false;
                const actualFocus = jsonValidationParams.appFocus;
                const expectedFocus = this.stepObj.Validation[valIdx].Status.expected;
                if (actualFocus === expectedFocus) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
                let failedMessage;
                if (!validationTestSuccess) {
                    failedMessage = this.stepObj.Validation[valIdx].FailMessage;
                }
                validationDetails = {
                    validated: validationTestSuccess,
                    message: failedMessage,
                    validationType: "status",
                    param: statusParam,
                    expected: expectedFocus,
                    actual: actualFocus,
                };
            } else if (statusParam === "AppRunning") {
                validationTestSuccess = false;
                const expectedRunning = this.stepObj.Validation[valIdx].Status.expected;
                if (expectedRunning) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
                let failedMessage;
                if (!validationTestSuccess) {
                    failedMessage = this.stepObj.Validation[valIdx].FailMessage;
                }
                validationDetails = {
                    validated: validationTestSuccess,
                    message: failedMessage,
                    validationType: "status",
                    param: statusParam,
                    expected: expectedRunning,
                    actual: true,
                };
            } else if (statusParam === "AppCallerID") {
                validationTestSuccess = false;
                const expectedCallerID = this.stepObj.Validation[valIdx].Status.expected;
                const actualCallerID = jsonValidationParams.callerID;
                if (expectedCallerID === actualCallerID) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
                let failedMessage;
                if (!validationTestSuccess) {
                    failedMessage = this.stepObj.Validation[valIdx].FailMessage;
                }
                validationDetails = {
                    validated: validationTestSuccess,
                    message: failedMessage,
                    validationType: "status",
                    param: statusParam,
                    expected: expectedCallerID,
                    actual: actualCallerID,
                };
            }

            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else if (this.stepObj.Validation[valIdx].Notification) {
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "Notification Count Validation",
            //         "Notification": {
            //             "param": "GTE",
            //             "expected": 60
            //         }
            //     }
            // ]

            const notificationParam = this.stepObj.Validation[valIdx].Notification.param;
            validationTestSuccess = false;
            const actualNotificationCount = jsonValidationParams.count;
            const expectedNotificationCount = this.stepObj.Validation[valIdx].Notification.expected;
            if (notificationParam === "GTE") {
                if (actualNotificationCount >= expectedNotificationCount) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
            } else if (notificationParam === "GT") {
                if (actualNotificationCount > expectedNotificationCount) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
            } else if (notificationParam === "LTE") {
                if (actualNotificationCount <= expectedNotificationCount) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
            } else if (notificationParam === "LT") {
                if (actualNotificationCount < expectedNotificationCount) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
            } else if (notificationParam === "EQ") {
                if (actualNotificationCount === expectedNotificationCount) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
            }

            validationDetails = {
                validated: validationTestSuccess,
                validationType: "notification",
                param: notificationParam,
                expected: expectedNotificationCount,
                actual: actualNotificationCount,
            };

            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else if (this.stepObj.Validation[valIdx].MatchList) {
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "Signaling Match Test",
            //         "MatchList": {
            //             "list": "objectList",
            //             "param": "name",
            //             "match": "all",
            //             "values": ["HELD", "MPD ", "RSAT", "AEL", "USBD"]
            //         }
            //     }
            // ]
            const matchListName = this.stepObj.Validation[valIdx].MatchList.list;
            const matchParam = this.stepObj.Validation[valIdx].MatchList.param;
            const matchType = this.stepObj.Validation[valIdx].MatchList.match;
            const expectedMatchListVals = this.stepObj.Validation[valIdx].MatchList.values;
            validationTestSuccess = false;
            let actualMatchListVals = [];
            const actualMatchList = jsonValidationParams[matchListName];
            if (actualMatchList) {
                for (let actualIdx = 0; actualIdx < actualMatchList.length; actualIdx++) {
                    let actualListItem = actualMatchList[actualIdx];
                    actualMatchListVals.push(actualListItem[matchParam]);
                }
            }
            if (actualMatchListVals.length) {
                if (matchType === "any") {
                    for (let expectedIdx = 0; expectedIdx < expectedMatchListVals.length; expectedIdx++) {
                        let expectedValue = expectedMatchListVals[expectedIdx];
                        for (let actualIdx = 0; actualIdx < actualMatchListVals.length; actualIdx++) {
                            let actualListItem = actualMatchListVals[actualIdx];
                            if (expectedValue === actualListItem) {
                                this.validationTestSuccessCount++;
                                validationTestSuccess = true;
                                break; // matching "any" ==> done
                            }
                        }
                        if (validationTestSuccess) break; // done
                    }
                } else if (matchType === "all") {
                    if (actualMatchListVals.length == expectedMatchListVals.length) {
                        // "all" match means that at the least the expected and actual list size should be the same
                        let actualMatchCount = 0;
                        for (let expectedIdx = 0; expectedIdx < expectedMatchListVals.length; expectedIdx++) {
                            let expectedValue = expectedMatchListVals[expectedIdx];
                            for (let actualIdx = 0; actualIdx < actualMatchListVals.length; actualIdx++) {
                                let actualListItem = actualMatchListVals[actualIdx];
                                if (expectedValue === actualListItem) {
                                    actualMatchCount++;
                                    break; // found a match, now look at the next expected value
                                }
                            }
                        }
                        if (actualMatchCount === expectedMatchListVals.length) {
                            this.validationTestSuccessCount++;
                            validationTestSuccess = true;
                        }
                    }
                } else if (matchType === "partial") {
                    // partial text matching for all values
                    //
                    // Example:
                    //
                    // "Validation": [
                    //     {
                    //         "Name": "Alerting Signaling Notification Match List Test",
                    //         "MatchList": {
                    //             "list": "alertList",
                    //             "param": "alertingFragment",
                    //             "match": "partial",
                    //             "values": ["aeaId=\"1\"", "aeaId=\"2\"", "aeaId=\"3\""]
                    //         }
                    //     }
                    // ]

                    let actualMatchCount = 0;
                    for (let expectedIdx = 0; expectedIdx < expectedMatchListVals.length; expectedIdx++) {
                        let expectedValue = expectedMatchListVals[expectedIdx];
                        for (let actualIdx = 0; actualIdx < actualMatchListVals.length; actualIdx++) {
                            let actualListItem = actualMatchListVals[actualIdx];
                            if (actualListItem) {
                                if (actualListItem.includes(expectedValue)) {
                                    actualMatchCount++;
                                    break; // found a match, now look at the next expected value
                                }
                            }
                        }
                    }
                    if (actualMatchCount === expectedMatchListVals.length) {
                        this.validationTestSuccessCount++;
                        validationTestSuccess = true;
                    }
                }
            } else {
                // Empty list validation (the expected response from the receiver is an empty list)
                //
                // Example:
                //
                //     "Validation": [
                //         {
                //             "Name": "@5s Alerting Signaling Match List Test",
                //             "MatchList": {
                //                 "list": "alertList",
                //                 "param": "alertingType",
                //                 "match": "all",
                //                 "values": []
                //             }
                //         }
                //     ]
                if (expectedMatchListVals.length === 0 && actualMatchListVals.length == expectedMatchListVals.length) {
                    this.validationTestSuccessCount++;
                    validationTestSuccess = true;
                }
            }

            validationDetails = {
                validated: validationTestSuccess,
                validationType: "match_list",
                param: matchParam,
                match: matchType,
                expected: expectedMatchListVals,
                actual: actualMatchListVals,
            };

            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else if (this.stepObj.Validation[valIdx].Auto) {
            this.validationTestCount++;
            // Example:
            //
            // "Validation": [
            //     {
            //         "Name": "setRMPURL Result Branching Validation",
            //         "Auto": {
            //             "validated": true,
            //             "message": "The setRMPURL API returned error -21 as expected!"
            //         }
            //     }
            // ]
            validationTestSuccess = this.stepObj.Validation[valIdx].Auto.validated;
            if (validationTestSuccess) this.validationTestSuccessCount++;
            validationDetails = {
                validated: validationTestSuccess,
                validationType: "auto",
                message: this.stepObj.Validation[valIdx].Auto.message,
            };
            if (validationTestDoneCallback) {
                validationTestDoneCallback(aborted, validationDetails);
            }
        } else {
            this.logManager.debug(`No validation tests were specified or the specified validation test is not supported.`);
        }
    }

    isEqual(val1, val2) {
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (areObjects) {
            return this.deepEqual(val1, val2);
        } else {
            return val1 === val2;
        }
    }

    deepEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                return false;
            }
        }

        return true;
    }

    isObject(object) {
        return object != null && typeof object === "object";
    }
}

class StepAPICall extends ValidatingTestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "Not Run");
        this.responseHandled = false;
    }

    execute(doneCallback) {
        try {
            const rpcCall = {
                method: this.stepObj.method,
                params: this.stepObj.params,
            };

            // show the test data
            this.uiManager.clearDisplay(true, true, false);
            let reqPretty = JSON.stringify(rpcCall, null, 2); // spacing level = 2
            let sessionHasFailure = this.storageManager.getTestRunSessionValidationFailure();
            this.uiManager.displayMessage(reqPretty, undefined, sessionHasFailure);

            // give 5s to the receiver to return an error when invoking launchApp API. When successful
            // this API does not return, therefore we need a timeout in order to avoid getting stuck here.
            let timeout_ms;
            if (rpcCall.method === "org.atsc.launchApp") timeout_ms = 5000;

            this.responseHandled = false;
            const _self = this;
            this.ctvFacade.sendMessage(
                rpcCall,
                function (apiReq, apiResponse) {
                    let apiRequest = apiReq ? apiReq : rpcCall;
                    if (!_self.responseHandled) {
                        _self.responseHandled = true;
                        // show results
                        _self.uiManager.clearDisplay(true, true, false, true);
                        let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                        let message = reqPretty.concat("\n" + respPretty);
                        _self.uiManager.displayMessage(message, undefined, sessionHasFailure);

                        // validate
                        if (_self.stepObj.Validation) {
                            _self.validate(apiRequest, apiResponse, (aborted, stepValidated) => {
                                if (aborted) {
                                    doneCallback(aborted);
                                } else {
                                    let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                                    if (!stepValidated) {
                                        if (!sessionHasFailure && !stepValidated) {
                                            sessionHasFailure = true;
                                            _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);
                                        }
                                    }
                                    _self.testResults = stepValidated ? "Ok (Validated)" : "Error (Validation Failure)";

                                    // then show validated results
                                    _self.uiManager.clearDisplay(true, true, false, true);
                                    let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                                    let message = reqPretty.concat("\n" + respPretty);
                                    _self.uiManager.displayMessage(message, stepValidated, sessionHasFailure);
                                    doneCallback(false);
                                }
                            });
                        } else {
                            _self.logManager.info("Executing Test Step", {
                                stepType: _self.stepObj.stepType,
                                testScriptId: _self.testScriptId,
                                ctvcvs: _self.stepObj.CTVCVS,
                                apiCall: apiRequest,
                                apiResp: apiResponse,
                            });

                            _self.testResults = "Ok";

                            // then show results
                            _self.uiManager.clearDisplay(true, true, false, true);
                            let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                            let message = reqPretty.concat("\n" + respPretty);
                            _self.uiManager.displayMessage(message, undefined, sessionHasFailure);
                            doneCallback(false);
                        }
                    } else {
                        _self.logManager.warning("Executing Test Step (duplicate)", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            apiCall: apiRequest,
                            apiResp: apiResponse,
                        });
                    }
                },
                timeout_ms
            );
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepKeyChoice extends TestStep {
    constructor(stepObj, testScriptId, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "Not Run");
        this.choiceItems = [];
        this.choiceItemsData = stepObj.ChoiceItems;
        this.choiceItemSelected;

        for (let choiceIdx = 0; choiceIdx < stepObj.ChoiceItems.length; choiceIdx++) {
            let testScriptId_choice = `${testScriptId}.${choiceIdx + 1}`;
            let choiceItem = new ChoiceItem(stepObj.ChoiceItems[choiceIdx], testScriptId_choice, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr);
            this.choiceItems.push(choiceItem);
        }
    }

    execute(doneCallback) {
        try {
            const _self = this;
            this.uiManager.clearDisplay(true, true, false);
            this.uiManager.waitForKeySelection(
                this.choiceItemsData,
                (keyCode) => {
                    _self.choiceItemSelected = _self.choiceItems.find((item) => item.data.Keycode === keyCode);
                    // execute the selected choice-item's steps
                    if (_self.choiceItemSelected) {
                        _self.logManager.info("Executing Test Step", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            testChoice: _self.choiceItemSelected.name,
                        });
                        _self.choiceItemSelected.execute((aborted) => {
                            _self.testResults = "Choice Item";
                            doneCallback(aborted);
                        });
                    } else {
                        doneCallback(false);
                    }
                },
                "Please make a choice by pressing one of the indicated keys..."
            );
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }

    getTestResults(level) {
        let testResults = super.getTestResults(level);
        if (this.testResults) {
            if (this.choiceItemSelected) {
                testResults = `${this.testResults}\n${this.choiceItemSelected.testSummary(level + 1)}`;
            } else {
                testResults = this.testResults;
            }
        }
        return testResults;
    }
}

class ChoiceItem extends TestStepList {
    constructor(choiceItemObj, testScriptId, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(choiceItemObj.Prompt, testScriptId, choiceItemObj.Steps, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr);
        this.data = choiceItemObj;
    }

    execute(doneCallback) {
        // this.logManager.info(`Executing Selected Test Choice "${this.name}"`);
        this.executeTestSteps(this.testSteps[this.currentStep], doneCallback);
    }
}

class StepKeyWait extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Key Wait)");
        this.prompt = stepObj.Prompt;
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                action: "Start",
            });
            const _self = this;
            this.uiManager.waitKeypress(
                ["Enter", "Back"],
                (keyCode) => {
                    if (keyCode === "Enter") {
                        _self.logManager.info("Executing Test Step", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            action: "Activation",
                        });
                        if (doneCallback) doneCallback(false);
                    } else {
                        _self.uiManager.clearDisplay(true, true, false);
                        _self.uiManager.displayTestInfo("Test steps aborted by user!");
                        if (doneCallback) doneCallback(true);
                    }
                },
                _self.prompt
            );
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepTimedWait extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Timed Wait)");
        this.prompt = stepObj.Prompt;
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                action: "Start",
				waitTime: this.stepObj.WaitTime,
            });
            const _self = this;

            const WAIT_TIME_MS = 100;
            let waitTimeLeft_ms = _self.stepObj.WaitTime;

            let timer = new Utils.ElapsedTimer();
            let timerId = setInterval(() => {
                // console.log(`Waiting for ${waitTimeLeft_ms / 1000} seconds...`);
                if (waitTimeLeft_ms > 0) {
                    let elapsedTime = timer.end();
                    waitTimeLeft_ms -= elapsedTime;
                    timer.start();
                    _self.uiManager.displayPrompt(`${_self.prompt} (${Math.ceil(waitTimeLeft_ms / 1000)} seconds)`);
                } else {
                    console.log(`Done Waiting!`);
                    // done waiting
                    clearInterval(timerId);

                    _self.logManager.info("Executing Test Step", {
                        stepType: _self.stepObj.stepType,
                        testScriptId: _self.testScriptId,
                        ctvcvs: _self.stepObj.CTVCVS,
                        action: "End",
						waitTime: this.stepObj.WaitTime,
                    });

                    // cancel the keypress "Cancel" for this step
                    _self.uiManager.clearDisplay(true, true, true);
                    if (doneCallback) doneCallback(false);
                }
            }, WAIT_TIME_MS);

            this.uiManager.waitKeypress(
                ["Back"],
                (keyCode) => {
                    if (keyCode === "Back") {
                        // cancel the timeout timer
                        clearInterval(timerId);
                        // clear the "Cancel/Back" keypress response action and display the abort message
                        _self.uiManager.clearDisplay(true, true, false);
                        _self.uiManager.displayTestInfo("Test steps aborted by user!");
                        if (doneCallback) doneCallback(true);
                    }
                },
                `${_self.prompt} (${Math.ceil(waitTimeLeft_ms / 1000)} seconds)`
            );
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepExpectNotify extends ValidatingTestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "Not Run");
        this.id = stepObj.Id;
        this.canceled = false;
        this.responseHandled = false;
        this.notificationCount = 0;
    }

    execute(doneCallback) {
        try {
            const _self = this;

            this.responseHandled = false;

            let expectedTimeout = this.stepObj.ExpectedTimeout;
            if (!expectedTimeout) expectedTimeout = false;

            const promptStr = `Waiting for "${this.id}" notification`;

            const WAIT_TIME_MS = 100;
            let waitTimeLeft_ms = _self.stepObj.WaitTime;

            let timer = new Utils.ElapsedTimer();
            let timerId = setInterval(() => {
                // console.log(`Waiting for ${waitTimeLeft_ms / 1000} seconds...`);
                if (waitTimeLeft_ms > 0) {
                    let elapsedTime = timer.end();
                    waitTimeLeft_ms -= elapsedTime;
                    timer.start();
                    _self.uiManager.displayPrompt(`${promptStr} (${Math.ceil(waitTimeLeft_ms / 1000)} seconds)`);
                } else {
                    console.log(`Done Waiting for Notifications!`);
                    // done waiting
                    clearInterval(timerId);

                    // cancel the notifications
                    _self.cancel(() => {
                        // we need to wait until the UNSUBSCRIBE call is processed (and a response received)
                        // in order to continue, otherwise when the UNSUBSCRIBE response is received the wrong
                        // API callback function could be invoked.

                        let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                        if (!expectedTimeout) {
                            if (!sessionHasFailure && !expectedTimeout) {
                                sessionHasFailure = true;
                                _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);
                            }
                        }

                        // show results
                        _self.uiManager.clearDisplay(true, true, false);
                        let reqPretty = JSON.stringify(rpcCall, null, 2); // spacing level = 2
                        let warnMsg = `Step "ExpectNotify" timed-out after ${_self.stepObj.WaitTime} ms!`;
                        let message = reqPretty.concat("\n" + warnMsg);
                        _self.uiManager.displayMessage(message, expectedTimeout, sessionHasFailure);

                        let validationObj = [];
                        validationObj.push({
                            validated: expectedTimeout,
                            message: warnMsg,
                            validationType: "timeout",
                            expected: expectedTimeout,
                            actual: true,
                        });
                        _self.logManager.info("Executing Test Step", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            action: "Validate",
                            timeout: _self.stepObj.WaitTime,
                            apiCall: rpcCall,
                            validation: validationObj,
                            result: expectedTimeout,
                        });

                        if (expectedTimeout) {
                            _self.testResults = `Ok (Validated)`;
                        } else {
                            _self.testResults = `Error: ${warnMsg}`;
                        }

                        if (doneCallback) doneCallback(false);
                    });
                }
            }, WAIT_TIME_MS);

            // wait for user abort
            this.uiManager.waitKeypress(
                ["Back"],
                (keyCode) => {
                    if (keyCode === "Back") {
                        // cancel the timeout timer
                        clearInterval(timerId);
                        // cancel the notifications
                        _self.cancel(() => {
                            // we need to wait until the UNSUBSCRIBE call is processed (and a response received)
                            // in order to continue, otherwise when the UNSUBSCRIBE response is received the wrong
                            // API callback function could be invoked.

                            // clear the "Cancel/Back" keypress response action and display the abort message
                            _self.uiManager.clearDisplay(true, true, false);
                            _self.uiManager.displayTestInfo("Test steps aborted by user!");

                            let warnMsg = "Test aborted by user!";
                            _self.logManager.warning(warnMsg);

                            _self.testResults = `Error: ${warnMsg}`;

                            if (doneCallback) doneCallback(true);
                        });
                    }
                },
                promptStr
            );

            // request notification
            let rpcCall = ATSCCommands.SUBSCRIBE;
            rpcCall.params.msgType = [this.id];

            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                action: "Start",
                timeout: this.stepObj.WaitTime,
                apiCall: rpcCall,
            });

            let processedTimeoutValidation = false;
            const notificationStartTime = new Date().getTime();

            let startedWaitingForNotificationLength = false;

            // now wait for notifications to be sent by the A/344 receiver
            this.ctvFacade.waitForNotification((apiResponse) => {
                _self.logManager.debug("Notification", apiResponse);

                let apiRequest = rpcCall;

                const notificationEndTime = new Date().getTime();
                const notificationElapsedTime = notificationEndTime - notificationStartTime;

                // process the notification if it's the first one OR the test step indicates the need to
                // process notifications for a specific length of time AND that length of time has not yet elapsed
                if (!_self.responseHandled || (_self.stepObj.NofificationLength && notificationElapsedTime <= _self.stepObj.NofificationLength)) {
                    // show results
                    _self.uiManager.clearDisplay(true, true, false, true);
                    let reqPretty = JSON.stringify(apiRequest, null, 2); // spacing level = 2
                    let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                    let message = reqPretty.concat("\n" + respPretty);
                    let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                    _self.uiManager.displayMessage(message, undefined, sessionHasFailure);

                    // make sure we only consider asynchronous notifications (msgType == _self.id) or event streams (schemeIdUri == _self.id)
                    if (
                        !_self.canceled &&
                        apiResponse.method === ATSCCommands.NOTIFY.method &&
                        (apiResponse.params.msgType == _self.id || apiResponse.params.schemeIdUri == _self.id)
                    ) {
                        _self.responseHandled = true;

                        // cancel the timeout timer
                        clearInterval(timerId);

                        _self.logManager.info("Executing Test Step", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            action: "Notified",
                            timeout: _self.stepObj.WaitTime,
                            apiCall: apiRequest,
                            apiResp: apiResponse,
                        });

                        _self.notificationCount++;

                        // set a timer and when it expires validate the number of notifications received
                        if (!startedWaitingForNotificationLength) {
                            startedWaitingForNotificationLength = true;
                            _self.waitForNotificationLength(rpcCall, expectedTimeout, doneCallback);
                        }
                        // validate if timeout was not expected
                        if (!expectedTimeout) {
                            // disregard processing this single notification if we're expecting multiple notifications
                            if (!_self.stepObj.NofificationLength) {
                                // stop processing notifications
                                _self.ctvFacade.stopWaitingForNotifications();

                                if (_self.stepObj.Validation) {
                                    _self.validate(apiRequest, apiResponse, (aborted, stepValidated) => {
                                        if (aborted) {
                                            if (doneCallback) doneCallback(aborted);
                                        } else {
                                            let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                                            if (!stepValidated) {
                                                if (!sessionHasFailure && !stepValidated) {
                                                    sessionHasFailure = true;
                                                    _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);
                                                }
                                            }
                                            _self.testResults = stepValidated ? "Ok (Validated)" : "Error (Validation Failure)";

                                            _self.cancel(() => {
                                                // we need to wait until the UNSUBSCRIBE call is processed (and a response received)
                                                // in order to continue, otherwise when the UNSUBSCRIBE response is received the wrong
                                                // API callback function could be invoked.

                                                // show validated results
                                                _self.uiManager.clearDisplay(true, true, false);
                                                let reqPretty = JSON.stringify(apiRequest, null, 2); // spacing level = 2
                                                let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                                                let message = reqPretty.concat("\n" + respPretty);
                                                _self.uiManager.displayMessage(message, stepValidated, sessionHasFailure);
                                                if (doneCallback) doneCallback(false);
                                            });
                                        }
                                    });
                                } else {
                                    _self.testResults = `Ok`;

                                    // cancel the notifications
                                    _self.cancel(() => {
                                        // we need to wait until the UNSUBSCRIBE call is processed (and a response received)
                                        // in order to continue, otherwise when the UNSUBSCRIBE response is received the wrong
                                        // API callback function could be invoked.

                                        // show results
                                        _self.uiManager.clearDisplay(true, true, false);
                                        let reqPretty = JSON.stringify(apiRequest, null, 2); // spacing level = 2
                                        let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                                        let message = reqPretty.concat("\n" + respPretty);
                                        let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                                        _self.uiManager.displayMessage(message, undefined, sessionHasFailure);
                                        if (doneCallback) doneCallback(false);
                                    });
                                }
                            }
                        } else {
                            if (!processedTimeoutValidation) {
                                processedTimeoutValidation = true;
                                // timeout was expected but a notification for the subscribed message type was received
                                let warnMsg = `Step "ExpectNotify" received a notification BUT was expected to time-out after ${_self.stepObj.WaitTime} ms!`;
                                let validationObj = [];
                                validationObj.push({
                                    validated: expectedTimeout,
                                    message: warnMsg,
                                    validationType: "timeout",
                                    expected: expectedTimeout,
                                    actual: true,
                                });
                                _self.logManager.info("Executing Test Step", {
                                    stepType: _self.stepObj.stepType,
                                    testScriptId: _self.testScriptId,
                                    ctvcvs: _self.stepObj.CTVCVS,
                                    action: "Validate",
                                    timeout: _self.stepObj.WaitTime,
                                    apiCall: rpcCall,
                                    validation: validationObj,
                                    result: false,
                                });

                                _self.testResults = `Error: ${warnMsg}`;

                                // we're done here, unless we're expecting multiple notifications
                                // this should not be possible and might be due to a missformed test script (cannot
                                // expected a timeout and at the same time multiple notifications within the same
                                // test)
                                if (!_self.stepObj.NofificationLength) {
                                    if (doneCallback) doneCallback(false);
                                }
                            }
                        }
                    } else {
                        _self.logManager.debug("Executing Test Step (disregarded)", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            action: "Notified",
                            timeout: _self.stepObj.WaitTime,
                            apiCall: apiRequest,
                            apiResp: apiResponse,
                        });
                    }
                } else {
                    if (!_self.stepObj.NofificationLength) {
                        _self.logManager.warning("Executing Test Step (duplicate)", {
                            stepType: _self.stepObj.stepType,
                            testScriptId: _self.testScriptId,
                            ctvcvs: _self.stepObj.CTVCVS,
                            action: "Notified",
                            timeout: _self.stepObj.WaitTime,
                            apiCall: apiRequest,
                            apiResp: apiResponse,
                        });
                    }
                }
            });
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            if (doneCallback) doneCallback(false);
        }
    }

    // set a timer and when it expires validate the number of notifications received
    waitForNotificationLength(apiRequest, expectedTimeout, doneCallback) {
        // wait for the indicated length of time before finishing this test step
        if (this.stepObj.NofificationLength) {
            const promptStr = `Receiving "${this.id}" notifications`;
            const WAIT_TIME_MS = 100;
            let notificationWaitTimeLeft_ms = this.stepObj.NofificationLength;

            const _self = this;
            let timer = new Utils.ElapsedTimer();
            let notificationTimerId = setInterval(() => {
                // console.log(`Waiting for ${notificationWaitTimeLeft_ms / 1000} seconds...`);
                if (notificationWaitTimeLeft_ms > 0) {
                    let elapsedTime = timer.end();
                    notificationWaitTimeLeft_ms -= elapsedTime;
                    timer.start();
                    _self.uiManager.displayPrompt(`${promptStr} (${Math.ceil(notificationWaitTimeLeft_ms / 1000)} seconds)`);
                } else {
                    console.log(`Done Receiving Notifications!`);
                    // done waiting
                    clearInterval(notificationTimerId);

                    // stop processing notifications
                    _self.ctvFacade.stopWaitingForNotifications();

                    // cancel the notifications
                    _self.cancel(() => {
                        // we need to wait until the UNSUBSCRIBE call is processed (and a response received)
                        // in order to continue, otherwise when the UNSUBSCRIBE response is received the wrong
                        // API callback function could be invoked.

                        if (_self.stepObj.Validation) {
                            _self.validate(apiRequest, { count: _self.notificationCount }, (aborted, stepValidated) => {
                                if (aborted) {
                                    if (doneCallback) doneCallback(aborted);
                                } else {
                                    let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                                    if (!stepValidated) {
                                        if (!sessionHasFailure && !stepValidated) {
                                            sessionHasFailure = true;
                                            _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);
                                        }
                                    }
                                    _self.testResults = stepValidated ? "Ok (Validated)" : "Error (Validation Failure)";

                                    // show results
                                    _self.uiManager.clearDisplay(true, true, false);
                                    let reqPretty = JSON.stringify(apiRequest, null, 2); // spacing level = 2
                                    let warnMsg = `Step "ExpectNotify" received ${_self.notificationCount} "${_self.id}" notifications within ${_self.stepObj.NofificationLength} ms!`;
                                    let message = reqPretty.concat("\n" + warnMsg);
                                    _self.uiManager.displayMessage(message, stepValidated, sessionHasFailure);

                                    if (doneCallback) doneCallback(false);
                                }
                            });
                        } else {
                            // show results
                            _self.uiManager.clearDisplay(true, true, false);
                            let reqPretty = JSON.stringify(apiRequest, null, 2); // spacing level = 2
                            let warnMsg = `Step "ExpectNotify" received ${_self.notificationCount} "${_self.id}" notifications within ${_self.stepObj.NofificationLength} ms!`;
                            let message = reqPretty.concat("\n" + warnMsg);
                            let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                            _self.uiManager.displayMessage(message, undefined, sessionHasFailure);

                            if (doneCallback) doneCallback(false);
                        }
                    });
                }
            }, WAIT_TIME_MS);
        }
    }

    cancel(doneCancelCallback) {
        this.canceled = true;
        // simply unsubscribe to the notification id
        let rpcCall = ATSCCommands.UNSUBSCRIBE;
        rpcCall.params.msgType = [this.id];
        const _self = this;
        this.ctvFacade.sendMessage(rpcCall, (apiReq, apiResp) => {
            _self.logManager.info("Cancel Notification Subscription", { msg: "Cancel", apiCall: rpcCall, req: apiReq, resp: apiResp });
            if (doneCancelCallback) doneCancelCallback();
        });
    }
}

class StepLog extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Log)");
        switch (stepObj.Value) {
            case "on":
                this.level = Constants.LOG_LEVEL.INFO;
                break;

            case "off":
                this.level = Constants.LOG_LEVEL.OFF;
                break;

            case "verbose":
                this.level = Constants.LOG_LEVEL.DEBUG;
                break;

            default:
                this.logManager.error("Unsupported Log test step value", this.stepObj);
                this.level = Constants.LOG_LEVEL.DEBUG;
                break;
        }
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                level: this.level.name,
            });

            this.logManager.setLevel(this.level);
            doneCallback(false);
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepLogMessage extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Log Message)");
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                message: this.stepObj.Message,
            });

            switch (this.stepObj.Level) {
                case Constants.LOG_LEVEL.DEBUG.name:
                    this.logManager.debug(this.stepObj.Message);
                    break;
                case Constants.LOG_LEVEL.WARNING.name:
                    this.logManager.warning(this.stepObj.Message);
                    break;
                case Constants.LOG_LEVEL.ERROR.name:
                    this.logManager.error(this.stepObj.Message);
                    break;
                case Constants.LOG_LEVEL.INFO.name:
                default:
                    this.logManager.info(this.stepObj.Message);
                    break;
            }
            doneCallback(false);
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepDisplay extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Display)");
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                message: this.stepObj.Prompt,
            });

            let sessionHasFailure = this.storageManager.getTestRunSessionValidationFailure();
            this.uiManager.displayMessage(this.stepObj.Prompt, undefined, sessionHasFailure);
            doneCallback(false);
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepDisplaySize extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Display Size)");
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
                size: this.stepObj.Value,
            });

            this.uiManager.displaySize(this.stepObj.Value);
            doneCallback(false);
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepAppStatus extends ValidatingTestStep {
    constructor(stepObj, testScriptId, scriptURL, callerID, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "Not Run");
        this.scriptURL = scriptURL;
        this.callerID = callerID;
    }

    execute(doneCallback) {
        try {
            const _self = this;
            if (this.stepObj.Validation) {
                let statusParams = [];
                let hidden = this.appHidden();
                let hasFocus = this.appHasFocus();
                statusParams = { appHidden: hidden, appFocus: hasFocus, appForcedClosed: [], callerID: this.callerID };
                for (let valIdx = 0; valIdx < this.stepObj.Validation.length; valIdx++) {
                    const statusParam = this.stepObj.Validation[valIdx].Status.param;
                    if (statusParam === "AppForcedClose") {
                        if (!this.stepObj.Validation[valIdx].Status.url) this.stepObj.Validation[valIdx].Status.url = this.scriptURL;
                        let appClosed = this.appForcedClosed(this.stepObj.Validation[valIdx].Status.url);
                        statusParams.appForcedClosed.push({ closed: appClosed, url: this.stepObj.Validation[valIdx].Status.url });
                    }
                }
                // check the app status now
                this.validate(undefined, statusParams, (aborted, stepValidated, validationList) => {
                    if (aborted) {
                        doneCallback(aborted);
                    } else {
                        let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                        if (!stepValidated) {
                            if (!sessionHasFailure && !stepValidated) {
                                sessionHasFailure = true;
                                _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);
                            }
                        }
                        _self.testResults = stepValidated ? "Ok (Validated)" : "Error (Validation Failure)";

                        // show validated results
                        _self.uiManager.clearDisplay(true, true, false);
                        let message = JSON.stringify(validationList, null, 2);
                        _self.uiManager.displayMessage(message, stepValidated, sessionHasFailure);
                        doneCallback(false);
                    }
                });
            } else {
                this.testResults = `Error: Validation missing from "AppStatus" test-step`;

                this.logManager.error("Executing Test Step (Validation missing)", {
                    stepType: this.stepObj.stepType,
                    testScriptId: this.testScriptId,
                    ctvcvs: this.stepObj.CTVCVS,
                });

                // show results
                this.uiManager.clearDisplay(true, true, false);
                let sessionHasFailure = this.storageManager.getTestRunSessionValidationFailure();
                this.uiManager.displayMessage(this.testResults, undefined, sessionHasFailure);
                doneCallback(false);
            }
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }

    appHidden() {
        return !this.uiManager.isVisible();
    }

    appHasFocus() {
        return this.uiManager.hasFocus();
    }

    appForcedClosed(scriptURL) {
        let scriptNotCompletedCount = this.storageManager.getTestRunScriptNotCompletedCount(scriptURL);
        if (this.scriptURL === scriptURL) {
            // >1 since we're counting this execution as well (which is expected)
            return scriptNotCompletedCount > 1;
        } else {
            return scriptNotCompletedCount > 0;
        }
    }
}

class StepValidate extends ValidatingTestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "Not Run");
    }

    execute(doneCallback) {
        try {
            // show the test data
            this.uiManager.clearDisplay(true, true, false);
            let reqPretty = JSON.stringify(this.stepObj.Validation, null, 2); // spacing level = 2
            let sessionHasFailure = this.storageManager.getTestRunSessionValidationFailure();
            this.uiManager.displayMessage(reqPretty, undefined, sessionHasFailure);

            const _self = this;
            // validate: either "Auto" or "Manual" validation supported for this test-step
            if (this.stepObj.Validation) {
                this.validate({ validation: this.stepObj.Validation }, {}, (aborted, stepValidated, validationList) => {
                    if (aborted) {
                        doneCallback(aborted);
                    } else {
                        let sessionHasFailure = _self.storageManager.getTestRunSessionValidationFailure();
                        if (!stepValidated) {
                            if (!sessionHasFailure && !stepValidated) {
                                sessionHasFailure = true;
                                _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);
                            }
                        }
                        _self.testResults = stepValidated ? "Ok (Validated)" : "Error (Validation Failure)";

                        // show validated results
                        _self.uiManager.clearDisplay(true, true, false);
                        let respPretty = JSON.stringify(validationList, null, 2);
                        let message = reqPretty.concat("\n" + respPretty);
                        _self.uiManager.displayMessage(message, stepValidated, sessionHasFailure);
                        doneCallback(false);
                    }
                });
            } else {
                let errMsg = "No Validation Tests were specified!";
                this.logManager.error(`Error Executing Test Step ${this.stepObj.stepType} (id: ${this.testScriptId}): ${errMsg}`);

                _self.testResults = "Error (Validation Test Missing)";
                sessionHasFailure = true;
                _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);

                // then show results
                _self.uiManager.clearDisplay(true, true, false, true);
                let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                let message = reqPretty.concat("\n" + errMsg);
                _self.uiManager.displayMessage(message, undefined, sessionHasFailure);
                doneCallback(false);
            }
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepAPICallBranch extends ValidatingTestStep {
    constructor(stepObj, testScriptId, callerID, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (API Branch)");

        // build the "then" branch
        this.branchThen = new BranchItem(stepObj.then, "Branch (THEN)", `${testScriptId}.1`, callerID, ctvFacade, uiMgr, logMgr, storageMgr);
        // build the "else" branch
        if (stepObj.else) {
            this.branchElse = new BranchItem(stepObj.else, "Branch (ELSE)", `${testScriptId}.2`, callerID, ctvFacade, uiMgr, logMgr, storageMgr);
        } else {
            this.branchElse = null;
        }
        this.branchItemExecuted;
    }

    execute(doneCallback) {
        try {
            const rpcCall = {
                method: this.stepObj.method,
                params: this.stepObj.params,
            };

            // show the test data
            this.uiManager.clearDisplay(true, true, false);
            let reqPretty = JSON.stringify(rpcCall, null, 2); // spacing level = 2
            let sessionHasFailure = this.storageManager.getTestRunSessionValidationFailure();
            this.uiManager.displayMessage(reqPretty, undefined, sessionHasFailure);

            this.responseHandled = false;
            const _self = this;
            this.ctvFacade.sendMessage(rpcCall, function (apiReq, apiResponse) {
                let apiRequest = apiReq ? apiReq : rpcCall;
                if (!_self.responseHandled) {
                    _self.responseHandled = true;
                    // show results
                    _self.uiManager.clearDisplay(true, true, false, true);
                    let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                    let message = reqPretty.concat("\n" + respPretty);
                    _self.uiManager.displayMessage(message, undefined, sessionHasFailure);
                }

                // validate: either "Auto" or "Manual" validation supported for this test-step
                if (_self.stepObj.Validation) {
                    _self.validate(
                        apiRequest,
                        apiResponse,
                        (aborted, stepValidated, validationList) => {
                            if (aborted) {
                                doneCallback(aborted);
                            } else {
                                _self.logManager.info("Executing Test Step", {
                                    stepType: _self.stepObj.stepType,
                                    testScriptId: _self.testScriptId,
                                    ctvcvs: _self.stepObj.CTVCVS,
                                    apiCall: apiRequest,
                                    apiResp: apiResponse,
                                    validation: validationList,
                                    result: stepValidated,
                                });
                                if (stepValidated) {
                                    // executing "then" branch steps
                                    if (_self.branchThen) {
                                        _self.branchItemExecuted = _self.branchThen;
                                        _self.branchThen.execute((aborted) => {
                                            doneCallback(aborted);
                                        });
                                    } else {
                                        doneCallback(false);
                                    }
                                } else {
                                    // executing "else" branch steps
                                    if (_self.branchElse) {
                                        _self.branchItemExecuted = _self.branchElse;
                                        _self.branchElse.execute((aborted) => {
                                            doneCallback(aborted);
                                        });
                                    } else {
                                        doneCallback(false);
                                    }
                                }
                            }
                        },
                        false
                    );
                } else {
                    let errMsg = "No Validation Tests were specified!";
                    _self.logManager.error(`Error Executing Test Step ${_self.stepObj.stepType} (id: ${_self.testScriptId}): ${errMsg}`);

                    _self.testResults = "Error (Validation Test Missing)";
                    sessionHasFailure = true;
                    _self.storageManager.setTestRunSessionValidationFailure(sessionHasFailure);

                    // then show results
                    _self.uiManager.clearDisplay(true, true, false, true);
                    let respPretty = JSON.stringify(apiResponse, null, 2); // spacing level = 2
                    let message = reqPretty.concat("\n" + errMsg);
                    _self.uiManager.displayMessage(message, undefined, sessionHasFailure);
                    doneCallback(false);
                }
            });
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }

    getTestResults(level) {
        return this.testResults ? `${this.testResults}\n${this.branchItemExecuted ? this.branchItemExecuted.testSummary(level + 1) : ""}` : super.getTestResults(level);
    }
}

class BranchItem extends TestStepList {
    constructor(branchItemObj, branchName, testScriptId, callerID, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(branchName, testScriptId, branchItemObj.Steps, undefined, callerID, ctvFacade, uiMgr, logMgr, storageMgr);
    }

    execute(doneCallback) {
        // this.logManager.info(`Executing Selected Test Choice "${this.name}"`);
        this.executeTestSteps(this.testSteps[this.currentStep], doneCallback);
    }
}

class StepEndOfTest extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (End of Test)");
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
            });

            doneCallback(false);
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepSessionStart extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Session Start)");
    }

    execute(doneCallback) {
        try {
            this.logManager.info("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: this.testScriptId,
                ctvcvs: this.stepObj.CTVCVS,
            });

            doneCallback(false);
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}

class StepSessionEnd extends TestStep {
    constructor(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, networkMgr) {
        super(stepObj, testScriptId, ctvFacade, uiMgr, logMgr, storageMgr, "N/A (Session End)");
        this.networkManager = networkMgr;
    }

    execute(doneCallback) {
        try {
            // retrieve Test PIN from the log-server
            const _self = this;
            this.networkManager.getTestRunData(
                Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                (nextPIN) => {
                    _self.logManager.debug(`Test Session PIN retrieved from log-server`, {
                        Source: "StepSessionEnd",
                        PIN_URL: Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                        PIN: nextPIN,
                    });

                    // Avoid showing the entire URL in SAPIS for security reasons
                    //     pinURL: protocol/host
                    let pathArray = Constants.LOG_URLS[Environment.ENVIRONMENT].PIN.split("/");
                    let pinURL = pathArray[0] + "//" + pathArray[2] + "/...";
                    _self.logManager.info("Executing Test Step", {
                        stepType: _self.stepObj.stepType,
                        testScriptId: _self.testScriptId,
                        ctvcvs: _self.stepObj.CTVCVS,
                        apiCall: { PIN_URL: pinURL },
                        apiResp: { PIN: nextPIN },
                        validation: [
                            {
                                validated: true,
                                validationType: "auto",
                            },
                        ],
                        result: true,
                    });

                    // clear the cache
                    _self.storageManager.deleteAll();
                    let testRunId = Utils.generateUUID();

                    // store the test run data ==> Pairing
                    _self.storageManager.setTestRunId(testRunId);
                    _self.storageManager.setTestRunPIN(nextPIN);

                    doneCallback(false);
                },
                (error) => {
                    _self.logManager.debug(`Error retrieving Test Session PIN from log-server`, {
                        Source: "StepSessionEnd",
                        PIN_URL: Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                        Error: error,
                    });

                    // Avoid showing the entire URL in SAPIS for security reasons
                    //     pinURL: protocol/host
                    let pathArray = Constants.LOG_URLS[Environment.ENVIRONMENT].PIN.split("/");
                    let pinURL = pathArray[0] + "//" + pathArray[2] + "/...";
                    _self.logManager.error("Executing Test Step", {
                        stepType: _self.stepObj.stepType,
                        testScriptId: _self.testScriptId,
                        ctvcvs: _self.stepObj.CTVCVS,
                        apiCall: { PIN_URL: pinURL },
                        apiResp: { error: error },
                        validation: [
                            {
                                validated: false,
                                validationType: "auto",
                                message: "Unable to retrieve test session PIN",
                            },
                        ],
                        result: false,
                    });
                    // clear the cache
                    _self.storageManager.deleteAll();

                    doneCallback(false);
                }
            );
        } catch (error) {
            this.testResults = `Test Step Error: ${error}`;
            this.logManager.error("Executing Test Step", {
                stepType: this.stepObj.stepType,
                testScriptId: `${this.testScriptId}`,
                validation: [
                    {
                        validated: false,
                        validationType: "auto",
                        message: `Error: ${error}`,
                    },
                ],
                result: false,
            });
            doneCallback(false);
        }
    }
}
