/** @format */

// "use strict";

import StorageManager from "./A344Test_StorageManager.js";
import NetworkManager from "./A344Test_NetworkManager.js";
import LogManager from "./A344Test_LogManager.js";
import UIManager from "./A344Test_UIManager.js";
import CTVFacade from "./A344Test_CTVFacade.js";
import TestManager from "./A344Test_TestManager.js";
import { ATSCCommands, Constants } from "./A344Test_Constants.js";
import { Environment } from "./Environment.js";
import * as Utils from "./A344Test_Utils.js";

class A344TestApp {
    constructor(testName, canvasElement) {
        this.testName = testName;
        // this.displayPIN = false;

        const _self = this;

        this.storageManager = new StorageManager();
        this.networkManager = new NetworkManager();
        const txtArea = document.getElementById("logArea");
        let version = `${Constants.VERSION}${Environment.ENVIRONMENT != Constants.ENV_TYPES.PROD ? `.${Environment.ENVIRONMENT}` : ""}`;
        this.logManager = new LogManager(Constants.APP_NAME, version, txtArea, Constants.LOG_URLS[Environment.ENVIRONMENT].LOG, this.networkManager, this.storageManager);

        let testRunId = this.storageManager.getTestRunId();
        let testRunPIN = this.storageManager.getTestRunPIN();
        if (!testRunId || !testRunPIN) {
            testRunId = Utils.generateUUID();
            // retrieve Test PIN from the log-server
            this.networkManager.getTestRunData(
                Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                (testPIN) => {
                    testRunPIN = testPIN;
                    _self.logManager.debug(`Test Session PIN retrieved from log-server`, {
                        Source: "A344TestApp",
                        PIN_URL: Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                        PIN: testPIN,
                    });
                    // store the test run data ==> Pairing
                    _self.storageManager.setTestRunId(testRunId);
                    _self.storageManager.setTestRunPIN(testRunPIN);
                    // set flag to display PIN to user
                    // _self.displayPIN = true;
                    // complete initialization
                    _self.initialize(canvasElement, testRunId, testRunPIN);
                },
                (error) => {
                    _self.logManager.error(`Error retrieving Test Session PIN from log-server`, {
                        Source: "A344TestApp",
                        PIN_URL: Constants.LOG_URLS[Environment.ENVIRONMENT].PIN,
                        Error: error,
                    });
                }
            );
        } else {
            this.logManager.debug(`A344TestApp:constructor() - Log metadata loaded from storage: PIN=${testRunPIN}`);
            // complete initialization (this device was already 'paired')
            this.initialize(canvasElement, testRunId, testRunPIN);
        }
    }

    initialize(canvasElement, testRunId, testRunPIN) {
        this.logManager.setTestRunID(testRunId);
        this.logManager.setTestRunPIN(testRunPIN);
        let version = `${Constants.VERSION}${Environment.ENVIRONMENT != Constants.ENV_TYPES.PROD ? `.${Environment.ENVIRONMENT}` : ""}`;
        this.logManager.debug(`${Constants.APP_NAME} (${version}) starting...`);
        this.logManager.debug(`Launch params: ${window.location.search}`);

        const _self = this;

        this.uiManager = new UIManager(
            canvasElement,
            function () {
                _self.abort(true, true);
            },
            function () {
                _self.start(testRunPIN, testRunId);
            },
            function () {
                _self.escapeSequenceHandler();
            },
            this.logManager
        );

        this.uiManager.show();
        this.uiManager.clearDisplay(false, false, true); // making sure menu choices are not active
        // Display Header info
        this.uiManager.displayHeader(Constants.APP_NAME + "  ", version);

        this.ctv = new CTVFacade(this.getWSurl(), this.logManager, this.uiManager, function (initErrorMsg) {
            if (initErrorMsg) {
                _self.abort(false, false);
                _self.uiManager.displayMessage(initErrorMsg, undefined, true);
            } else {
                _self.start(testRunPIN, testRunId);
            }
        });
        this.testManager = new TestManager(this.getCallerIDQuery(), this.ctv, this.uiManager, this.logManager, this.storageManager, this.networkManager);
    }

    getTestScriptName(testNameCallback) {
        let testURL;
        if (Constants.ENABLE_HELD_TESTSCRIPTS) {
            // HELD needs to be retrieved and processed. Each HTMLEntryPackage needs to be considered for the list of tests (bbandEntryPageUrl)
            const _self = this;
            this.ctv.sendMessage(ATSCCommands.HELD_SIGNALING_REQ, (rpcCall, messageObj) => {
                if (messageObj) {
                    if (messageObj.result) {
                        _self.logManager.info("HELD", messageObj.result);
                        if (messageObj.result.objectList) {
                            let bbandURLs = [];
                            for (let elIdx = 0; elIdx < messageObj.result.objectList.length; elIdx++) {
                                let element = messageObj.result.objectList[elIdx];
                                if (element.name === "HELD") {
                                    let parser = new DOMParser();
                                    let heldXMLDoc = parser.parseFromString(element.table, "text/xml");
                                    let entryPackageArray = heldXMLDoc.getElementsByTagName("HTMLEntryPackage");
                                    for (let htmlIdx = 0; htmlIdx < entryPackageArray.length; htmlIdx++) {
                                        let urlAttr = entryPackageArray[htmlIdx].attributes;
                                        if (urlAttr) {
                                            let urlNode = urlAttr.getNamedItem("bbandEntryPageUrl");
                                            if (urlNode) {
                                                bbandURLs.push(urlNode.value);
                                            }
                                        }
                                    }
                                }
                            }
                            if (bbandURLs.length > 1) {
                                let testNames = [];
                                for (let urlIdx = 0; urlIdx < bbandURLs.length; urlIdx++) {
                                    let url = bbandURLs[urlIdx];
                                    testNames.push(url.substring(url.lastIndexOf("/") + 1));
                                }
                                // the user has to choose which test/url to use
                                _self.uiManager.clearDisplay(true, true, false);
                                _self.uiManager.waitForSelection(
                                    testNames,
                                    (selectionIdx, abort) => {
                                        if (abort) {
                                            _self.abort(true, false);
                                        } else {
                                            testURL = bbandURLs[selectionIdx];
                                            if (testNameCallback) testNameCallback(testURL);
                                        }
                                    },
                                    `Select the Test to run and press "Enter" to continue...`
                                );
                            } else if (bbandURLs.length == 1) {
                                _self.logManager.info("HELD includes only 1 'HTMLEntryPackage' node.");
                                testURL = bbandURLs[0];
                                if (testNameCallback) testNameCallback(testURL);
                            } else {
                                _self.logManager.error("HELD does not include any 'bbandEntryPageUrl' attribute or 'HTMLEntryPackage' node!");
                                if (testNameCallback) testNameCallback();
                            }
                        } else {
                            _self.logManager.error("HELD does not include 'objectList'!");
                            if (testNameCallback) testNameCallback();
                        }
                    } else if (messageObj.error) {
                        _self.logManager.error("HELD", messageObj.error);
                        if (testNameCallback) testNameCallback();
                    }
                } else {
                    _self.logManager.error("HELD response is empty!");
                    if (testNameCallback) testNameCallback();
                }
            });
        } else {
            if (this.testName) {
                //this.logManager.info("URL Parsing", { msg: `The test name was passed in as an argument to the script: ${this.testName}` });
                // The test name was passed in as an argument to the script
                let pathName = window.location.pathname;
                let parts = pathName.split("/");
                let lastUrlComponent = "";
                if (pathName.lastIndexOf("/") !== pathName.length - 1) {
                    lastUrlComponent = parts[parts.length - 1];
                } else {
                    lastUrlComponent = parts[parts.length - 2];
                }
                if (lastUrlComponent.includes(".html")) {
                    pathName = pathName.replace(lastUrlComponent, "");
                }
                if (pathName[pathName.length - 1] != "/") pathName += "/";
                let portName = window.location.port ? ":" + window.location.port : "";
                let protocol = window.location.protocol ? window.location.protocol + "//" : "";
                testURL = protocol + window.location.hostname + portName + pathName + this.testName;
                console.log(`Host: ${window.location.hostname}, Path: ${window.location.pathname}, Name: ${this.testName}, URL: ${testURL}`);
            } else {
                //this.logManager.info("URL Parsing", { msg: `The test name is retrieved from the URL: ${window.location.search}` });
                // The test name is retrieved from the URL. The testName parameter should also include the
                // relative sub-path from the loaded index.html page location (e.g. "assets/tests/TestScript_All.json")
                let testName;
                let params = window.location.search.substring(1);
                params.split("&").some(function (part) {
                    var item = part.split("=");
                    if (item[0] == "testName") {
                        testName = decodeURIComponent(item[1]);
                        return true;
                    }
                });
                if (testName) {
                    let pathName = window.location.pathname;
                    if (pathName[pathName.length - 1] != "/") pathName += "/";
                    testURL = window.location.hostname + pathName + testName;
                }
            }
            if (testNameCallback) testNameCallback(testURL);
        }
    }

    getWSurl() {
        let params = window.location.search.substring(1);
        let result = Constants.WS_DEFAULT_URL;
        if (Constants.ENABLE_WSURL_PARAM) {
            // Example for WS URL ws://127.0.0.1:3355 in query string: ?wsURL=ws%3A%2F%2F127.0.0.1%3A3355
            params.split("&").some(function (part) {
                var item = part.split("=");
                if (item[0] == "wsURL") {
                    result = decodeURIComponent(item[1]);
                    return true;
                }
            });
        }
        this.logManager.debug(`Web Socket URL:  ${result} ${result === Constants.WS_DEFAULT_URL ? "(default)" : "(query string)"}`);
        return result;
    }

    getCallerIDQuery() {
        let params = window.location.search.substring(1);
        let result = "";
        // Example for Caller ID = appID1 in query string: ?callerId=appID1
        params.split("&").some(function (part) {
            var item = part.split("=");
            if (item[0] == "callerId") {
                result = decodeURIComponent(item[1]);
                return true;
            }
        });
        this.logManager.debug(`Caller ID Query:  ${result}`);
        return result;
    }

    start(testRunPIN, testRunId) {
        try {
            const _self = this;
            this.getTestScriptName((testURL) => {
                if (testURL) {
                    _self.testManager.runTests(testURL, testRunPIN, testRunId, (aborted) => {
                        _self.onTestsDone(aborted);
                    });
                } else {
                    _self.uiManager.displayInfo("Error: unable to retrieve test script name.");
                    _self.onTestsDone(false);
                }
            });
        } catch (error) {
            let version = `${Constants.VERSION}${Environment.ENVIRONMENT != Constants.ENV_TYPES.PROD ? `.${Environment.ENVIRONMENT}` : ""}`;
            this.logManager.error(`Error while running "${Constants.APP_NAME} (${version})": ${error}`);
        }
    }

    onTestsDone(aborted) {
        if (aborted) {
            this.logManager.warning("Test aborted!");
            this.abort(true, false);
        } else {
            console.log("Test completed!");
        }
    }

    escapeSequenceHandler() {
        this.testManager.toggleEndSession();
    }

    abort(userAborted, hideApp) {
        this.logManager.warning(`Test aborted by user!`);

        // whe the user aborts, a new test PIN/Timestamp will be requested next time around
        if (userAborted) {
            this.logManager.debug("A344TestApp:abort() - Removed all stored data.");
            this.storageManager.deleteAll();
        }
        if (hideApp) this.uiManager.hide();
    }
}

var testApp;

export default function RunA344TestApp(testName) {
    testApp = new A344TestApp(testName, canvas);
}
window.RunA344TestApp = RunA344TestApp;
