/** @format */

// "use strict";
import { ATSCCommands, Constants } from "./A344Test_Constants.js";
import ATSCSocketService from "./A344Test_WebSocket.js";
import ATSCSocketServiceSim from "./A344Test_WebSocketSim.js";
import { Environment } from "./Environment.js";

const InitState = {
    NONE: "none",
    ATSC: "atsc",
    LOCAL: "local",
};
export default class CTVFacade {
    constructor(wsURL, logMgr, uiMgr, initCompletedCallback) {
        this.wsURL = wsURL;
        this.logManager = logMgr;
        this.callbackMT = null;
        this.socketService = new ATSCSocketService(logMgr, uiMgr);
        this.initialized = InitState.NONE;
        this.uiManager = uiMgr;

        const _self = this;
        let atscSocketSim;
        this.socketService.setWebSocketConnection(
            wsURL,
            () => _self.onWSConnectionSuccess(initCompletedCallback),
            (errorMsg) => _self.onWSConnectionError(errorMsg, initCompletedCallback)
        );
    }

    onWSConnectionSuccess(initCompletedCallback) {
        try {
            this.logManager.debug("Launched in ATSC mode!");
            this.ctvDeviceInit(InitState.ATSC, initCompletedCallback);
        } catch (error) {
            this.logManager.error(`Error after successful Web Socket connection: ${error}`);
        }
    }

    onWSConnectionError(errorMsg, initCompletedCallback) {
        try {
            if (Environment.ENVIRONMENT != Constants.ENV_TYPES.PROD && this.initialized === InitState.NONE) {
                // emulate the WebSockets then!
                this.logManager.warning("Launched in ATSC off mode!");
                this.socketService = new ATSCSocketServiceSim(this.logManager);
                this.ctvDeviceInit(InitState.LOCAL, initCompletedCallback);
            } else {
                let message = "Unkown ATSC Web Socket Error!";
                if (errorMsg) message = `ATSC Web Socket Error: ${errorMsg}`;
                this.logManager.warning(message);
                initCompletedCallback(message);
            }
        } catch (error) {
            this.logManager.error(`Error after unsuccessful Web Socket connection: ${error}`);
        }
    }

    ctvDeviceInit(initState, initCompletedCallback) {
        this.initialized = initState;
        const _self = this;
        setTimeout(() => {
            // needed for device key-code mappings
            _self.socketService.sendMessage(ATSCCommands.DEVICE_INFO, (rpcCall, messageObj) => {
                _self.uiManager.setupKeys(messageObj);
                if (messageObj.result) {
                    if (messageObj.result.deviceId) _self.logManager.setDeviceID(messageObj.result.deviceId);
                    _self.logManager.info("Device Info", messageObj.result);
                } else if (messageObj.error) {
                    _self.logManager.error("Device Info", messageObj.error);
                } else {
                    _self.logManager.error("Device Info", messageObj);
                }
            });

            // making sure the receiver forwards the "ArrowLeft", "ArrowUP", "ArrowRight", "ArrowDown", "Ok/Select", "Back", "Numeric" keys to this app
            let keyRequest = ATSCCommands.REQUEST_KEYS;
            keyRequest.params.keys = ["ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown", "Enter", "Back", "Numeric"];
            _self.socketService.sendMessage(keyRequest, (rpcCall, messageObj) => {
                if (messageObj.result) {
                    _self.logManager.debug("Receiver RequestKeys Response", messageObj.result);
                } else if (messageObj.error) {
                    _self.logManager.error("Receiver RequestKeys Response", messageObj.error);
                } else {
                    _self.logManager.error("Receiver RequestKeys Response", messageObj);
                }
            });

            setTimeout(() => {
                initCompletedCallback();
            }, 1000);
        }, 2000);
    }

    queryServiceId(callbackFn) {
        try {
            this.socketService.queryServiceId((apiReq, apiResp) => {
                callbackFn(apiResp);
            });
        } catch (error) {
            this.logManager.error(`Error while querying for Service ID: ${error}`);
        }
    }

    queryMediaTime(callbackFn) {
        try {
            this.socketService.queryMediaTime((apiReq, apiResp) => {
                callbackFn(apiResp);
            });
        } catch (error) {
            this.logManager.error(`Error while querying for Media Time: ${error}`);
        }
    }

    sendMessage(rpcCall, callbackFn) {
        try {
            this.socketService.sendMessage(rpcCall, callbackFn);
        } catch (error) {
            this.logManager.error(`Error while sending Web Socket message: ${error}`);
        }
    }

    waitForNotification(callbackFn) {
        try {
            this.socketService.waitForNotification(callbackFn);
        } catch (error) {
            this.logManager.error(`Error while waiting for Web Socket notification: ${error}`);
        }
    }

    stopWaitingForNotifications() {
        try {
            this.socketService.stopWaitingForNotifications();
        } catch (error) {
            this.logManager.error(`Error while stop waiting for Web Socket notification: ${error}`);
        }
    }
}
